<div class="alert alert-warning" *ngIf="this.error != null">{{this.error}}</div>
<app-wishlist-create *ngIf="this.isLoaded && this.wishlistMember == null" [wishlistMember]="this.wishlistMember" (createdWishlistMember)="setWishlistMember($event)"></app-wishlist-create>
<div *ngIf="this.isLoaded && this.wishlistMember != null" class="container mt-5 justify-content-center">
  <div *ngIf="this.invitation?.key">
    <div class="row">
      <div class="col-sm-4">
        <strong>Email:</strong>
        {{this.invitation?.email}}
      </div>
      <div class="col-sm-4">
        <strong>Wishlist Member:</strong>
        {{ this.invitation?.wishlistMember.member.knownAs }}
      </div>
      <div class="col-sm-2">
        <strong>Key:</strong>
        {{ this.key }}
      </div>
      <div class="col-sm-2">
        <strong>{{ this.invitation?.statusCode }}:</strong>
         {{ this.invitation.statusDate | date }}
      </div>
    </div>
    <div class="row">
        <button class="btn btn-success my-2 my-sm-0" (click)="this.acceptInvitation()">Accept Invitation</button>
        <button class="btn btn-danger my-2 my-sm-0" (click)="this.rejectInvitation()">Reject Invitation</button>
    </div>
  </div>
  <div *ngIf="!this.invitation?.key" class="row">
    Unable to locate the invitation for {{ this.key }}.
  </div>
</div>
