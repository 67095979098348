import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClueChaseLocation } from 'src/app/_models/clueChaseLocation';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {
  
  @Input()
  clueChaseLocations: ClueChaseLocation[];
  @Input()
  editMode: boolean;
  @Output()
  deleteClueChaseLocation = new EventEmitter<ClueChaseLocation>();
  @Output()
  actionResponse = new EventEmitter<string>();

  showAction: boolean = false;
  showImage: string = null;

  constructor() { }

  ngOnInit() {
  }

  toggleAction(clueChaseLocation: ClueChaseLocation) {
    if (clueChaseLocation.location.code != null && (this.editMode || clueChaseLocation.location.code === clueChaseLocation.location.codeResponse)) {
      clueChaseLocation.location.showAction = !clueChaseLocation.location.showAction;
    }
  }
  
  delete (clueChaseLocation: ClueChaseLocation) {
    this.deleteClueChaseLocation.emit(clueChaseLocation);
  }

  setShow(image: string) {
    if (this.showImage === null) {
      this.showImage = image;
    } else {
      this.showImage = null;
    }
  }

  codeResponseMatched(codeResponse: string) {
    this.actionResponse.emit(codeResponse);
  }
}
