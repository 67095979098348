<block-ui>
  <div class="container">
    <div *ngIf="this.secretSanta">
      <table cellpadding="3" cellspacing="3">
        <tr>
          <th colspan="5" class="column-header">
            Secret Santa Detail
          </th>
        </tr>
        <tr>
          <th>Event</th>
          <th>Event Date</th>
          <th>Hosted By</th>
          <th>Status</th>
          <th>Status Date</th>
        </tr>
        <tr>
          <td>{{ this.secretSanta.event }}</td>
          <td>{{ this.secretSanta.eventDate | date }}</td>
          <td>{{ this.secretSanta.wishlistMember.member.knownAs }}</td>
          <td>{{ this.secretSanta.statusCode }}</td>
          <td>{{ this.secretSanta.statusDate | date:'medium' }}</td>
        </tr>
        <tr>
          <td colspan="5" class="description_row">
            {{ secretSanta.description }}
          </td>
        </tr>
      </table>
      <table style="border:5px;border-color:black;">
        <tr *ngIf="this.secretSantaInvitation?.recipientWishlistMember">
          <th>Recipient:</th>
          <td>
            <div class="image-box">
              <img  *ngIf="this.secretSantaInvitation?.recipientWishlistMember" (click)="this.navigateWishlistMember(this.secretSantaInvitation.recipientWishlistMember?.memberID)"
                draggable="false" src="{{this.secretSantaInvitation.recipientWishlistMember?.member.photoUrl || '../../assets/user.png'}}" alt="">
            </div>
            <span *ngIf="this.secretSantaInvitation?.recipientWishlistMember">
              {{secretSantaInvitation.recipientWishlistMember?.member.knownAs}}
            </span>
          </td>
          <td colspan="2"></td>
        </tr>
        <tr>
          <th colspan="4">Participant List</th>
        </tr>
        <tr *ngIf="(this.secretSanta.statusCode === 'PENDING') && (this.secretSanta.secretSantaInvitations | exclude: 'statusCode' : ['ACCEPTED', 'REJECTED']).length > 0">
          <td colspan="6" class="error">
            All participants must have responded with ACCEPTED or REJECTED ({{ (this.secretSanta.secretSantaInvitations | exclude: 'statusCode' : ['ACCEPTED', 'REJECTED']).length }} remaining).
          </td>
        </tr>
        <tr *ngIf="(this.secretSanta.statusCode === 'PENDING') && (this.secretSanta.secretSantaInvitations | filter: 'statusCode' : 'ACCEPTED').length < 2" >
          <td colspan="6" class="error">
            There must be at least 2 participants who have ACCEPTED
          </td>
        </tr> 
        <tr *ngIf="(this.secretSanta.statusCode === 'PENDING') && (this.secretSanta.secretSantaInvitations | filter: 'statusCode' : 'ACCEPTED').length < 4" >
          <td colspan="6" class="warning">
            WARNING: there must be at least 4 participants who have ACCEPTED if you want the exchange to be a secret!<br/>
            &nbsp;&nbsp;&nbsp;<i>Since no participant can be assigned to themself, 2 or 3 participants reveals the exchange!</i>
          </td>
        </tr> 
        <tr>
          <th>Email</th>
          <th>Accepted By</th>
          <th>Status</th>
          <th>Date</th>
          <th></th>
        </tr>
        <tr *ngFor="let secretSantaInvitation of secretSanta.secretSantaInvitations">
          <td>{{secretSantaInvitation.email}}</td>
          <td>
            <img *ngIf="secretSantaInvitation.wishlistMember != null" draggable="false" src="{{secretSantaInvitation.wishlistMember?.member.photoUrl || '../../assets/user.png'}}" alt="">
            <br/>
            {{secretSantaInvitation.wishlistMember?.member.knownAs}}
          </td>
          <td>{{secretSantaInvitation.statusCode}}</td>
          <td>{{secretSantaInvitation.statusDate | date: 'medium'}}</td>
          <td>
            <ng-container *ngIf="this.email === secretSantaInvitation.email && this.secretSanta.statusCode === 'PENDING' && (secretSantaInvitation.statusCode === 'SENT' || secretSantaInvitation.statusCode === 'RESENT')">
              <button class="btn btn-primary" (click)="respondSecretSantaInvitation(secretSantaInvitation, true);">Accept</button>
              <button class="btn btn-danger" (click)="respondSecretSantaInvitation(secretSantaInvitation, false);">Reject</button>
            </ng-container>
          </td>
        </tr>
      </table>
    </div>
  </div>
</block-ui>