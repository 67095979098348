import { Pipe, PipeTransform } from '@angular/core';
import { ClueChaseLocation } from 'src/app/_models/clueChaseLocation';
import { Location } from 'src/app/_models/location';

@Pipe({
    name: 'filterLocation',
    pure: false
})
export class FilterLocation implements PipeTransform {
    transform(items: Location[], latitude: number, longitude: number, maxDistance: number): any[] {
        if (!items) { return []; }

        return items.filter(location => this.asTheCrowFlies(latitude, longitude, location.latitude, location.longitude) <= maxDistance);
    }

    asTheCrowFlies(x1: number, y1: number, x2: number, y2: number) {

        var result = 0;
        if (x1 === 0 || y1 === 0 || x2 === 0 || y2 === 0) {
          return;
        }
        const RADIANS: number = 180 / 3.14159265;
        const METRES_IN_MILE: number = 1609.34;
        
        if (x1 == x2 && y1 == y2) {
          result = 0;
        
        } else {
          // Calculating Distance between Points
          var lt1 = x1 / RADIANS;
          var lg1 = y1 / RADIANS;
          var lt2 = x2 / RADIANS;
          var lg2 = y2 / RADIANS;
        
          // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
          result = (3958.8 * METRES_IN_MILE) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
        }
        return result;
      }
}
