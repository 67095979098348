import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: any): any[] {
        if (!items) { return []; }
        if (value === null) {
            return items;
        }
        if (Array.isArray(value)) {
            let returnValues = [];
            value.map(v => {
                returnValues = returnValues.concat(items.filter(it => it[field] === v));
            });
            // tslint:disable-next-line: only-arrow-functions
            const result = returnValues.filter(function(item, pos) {
                return returnValues.indexOf(item) === pos;
            });
            return result;
        } else {
            return items.filter(it => it[field] === value);
        }
    }
}
