<div class="modal-header">
    <h4 class="modal-title">{{ this.modalTitle }}</h4>
    <button type="button" class="fa fa-close" aria-label="Close"
      (click)="cancel()">
   </button>  
  </div>
  <div class="modal-body">
    <form #searchForm name="searchForm">
        <div class="container">
            <div class="row input-group">
                <div class="input-group-prepend">
                    <label for="searchRadius" class="input-group-text">Search Radius (meters):</label>
                </div>
                <input type="text" style="width:75px" name="searchRadius" id="searchRadius" [(ngModel)]="searchRadius" placeHolder="Max Distance">
                <div class="input-group-prepend">
                    <label for="searchText" class="input-group-text">Search Text:</label>
                </div>
                <input type="text" style="width:75px" name="searchText" id="searchText" [(ngModel)]="searchText" placeHolder="Type part of the name or hint">
            </div>
        </div>
    </form>
    <table>
      <tr>
          <th>Name</th>
          <th>Hint</th>
          <th>Distance</th>
          <th>Created By</th>
        </tr>
        <tr *ngFor="let location of locations | filterWild: 'name': searchText | filterLocation: position.coords.latitude: position.coords.longitude: searchRadius" (click)="selectLocation(location)">
          <td>
            {{ location.name }}
          </td>
          <td>
            {{ location.hint}}
          </td>
          <td>
             {{ asTheCrowFlies(position, location.latitude, location.longitude) }}
          </td>
          <td>{{ location.member.knownAs }}</td>
        </tr>
        <tr *ngIf="locations == null">
            Loading locations...
        </tr>
    </table>
  </div>
  
  <div class="modal-footer">
      <button type="button" (click)="cancel();">Cancel</button>
  </div>