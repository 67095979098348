<div class="container mt-5">
  <div *ngIf="authService.decodedToken">
    Welcome to Wyssweb!
  </div><br/>
  <!-- <div *ngIf="!authService.decodedToken">
    <div *ngIf="!registerMode" style="text-align: center">
        <h1>Wyssweb Registration</h1>
        <p class="lead">Sign in to make full use of this webiste!  You can create a new account, or sign in with Facebook or Google (coming Soon &trade;).</p>
        <div class="text-center">
          <button class="btn btn-primary btn-lg mr-2" (click)="registerToggle()">Register</button>
          <button class="btn btn-info btn-lg">Learn more</button>
        </div>
      </div>
    
      <div *ngIf="registerMode" class="container">
        <div class="row justify-content-center">
          <div class="col-4">
            <app-register (cancelRegister)="this.cancelRegisterMode($event)"></app-register>
          </div>
        </div>
      </div>
  </div>   -->
  <div class="justify-content-center">
    Wyssweb now allows logging in with social media (Google and Facebook). If you currently log in with 
    an email and password, you should first go to your Member profile to link with social media.<br />
    <b>If your Member profile is not linked, logging in with social media will create a new account!</b><br/><br/>
    Feedback to <a href="mailto:administrator@wyssweb.com">Wyssweb</a>
  </div>
</div>