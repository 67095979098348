<nav class="navbar navbar-expand-md navbar-dark bg-primary">
  <div class="container">
      
      <div class="dropdown" dropdown>
        <a class="dropdown-toggle text-light" dropdownToggle><span class="mr-2">Menu</span></a>
      
        <div class="dropdown-menu mt-3" *dropdownMenu>
          <a class="dropdown-item" href="https://odysseyhunt.com"><i class="fa fa-map-marker">Odyssey Hunt</i></a>
          <div class="dropdown-divider"></div>

          <span *ngIf="this.authService.loggedIn()">
              <a *ngIf="!this.authService.isConfirmed()" class="dropdown-item" (click)="waitingForConfirmation()">
                <i class="fa fa-group">Click here to confirm your account</i>
              </a>
              <a *ngIf="this.authService.isConfirmed()" class="dropdown-item" [routerLink]="['/wishlist']">
                <i class="fa fa-group">Wishlist</i>
              </a>
          </span>

          <div class="dropdown-divider"></div>
          
          <a class="dropdown-item" [routerLink]="['/games']"><i class="fa fa-list">Game List</i></a>
        </div>
      </div>
      
      <a class="navbar-brand" [routerLink]="['/']"><i class="fa fa-home">Wyssweb</i></a>
  
      <div *ngIf="loggedIn()" class="dropdown" dropdown>
        <a class="dropdown-toggle text-light" dropdownToggle>
            <img draggable="false" src="{{photoUrl || '../../assets/user.png'}}" alt="Profile">
            {{this.authService.currentMember.knownAs}}
        </a>
      
        <div class="dropdown-menu" *dropdownMenu>
          <a class="dropdown-item" [routerLink]="['/member/edit/' + this.authService.currentMember.id]">
            <i class="fa fa-user"></i>Edit Profile</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logOut()"><i class="fa fa-sign-out"></i>Logout</a>
        </div>
      </div>  
      <button *ngIf="!loggedIn()" class="btn btn-success my-2 my-sm-0" type="button" (click)="login();">Login</button>

  </div>
</nav>





<!-- <ul id="menu-main-menu-admin" class="navbar-nav"><li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-3381" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children nav-item menu-item-3381 dropdown">
  <a title="Human Resources" href="#" data-toggle="dropdown" class="nav-link dropdown-toggle" aria-haspopup="true">Human Resources <span class="caret"></span></a>
  <ul role="menu" class=" dropdown-menu" >
    <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-27077" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-27077"><a title="Test Link" href="#" class="dropdown-item">Test Link</a></li>
    <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-27028" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-27028 dropdown"><a title="HR Reports" href="#" data-toggle="dropdown" class="nav-link dropdown-toggle" aria-haspopup="true">HR Reports <span class="caret"></span></a>
    <ul role="menu" class=" dropdown-menu" >
      <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-27029" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-27029"><a title="Change Of Status" href="https://example.com/change-of-status/" class="dropdown-item">Change Of Status</a></li>
      <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-27031" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-27031"><a title="Failure To Start" href="https://example.com/failure-to-start/" class="dropdown-item">Failure To Start</a></li>
      <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-27032" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-27032"><a title="Intent To Hire" href="https://example.com/intent-to-hire/" class="dropdown-item">Intent To Hire</a></li>
      </ul>
  </li>
  </ul> -->