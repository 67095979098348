<block-ui>
    <form #searchForm name="searchForm">
    <div class="container" *ngIf="locations!=null && this.editLocation == null">
        <div class="row input-group">
            <div class="input-group-prepend">
                <label for="searchRadius" class="input-group-text">Search Radius (meters):</label>
            </div>
            <input type="text" style="width:75px" [disabled]="this.autoLocation === false" name="searchRadius" id="searchRadius" [(ngModel)]="searchRadius" placeHolder="Max Distance">
        </div>
        <div class="row input-group">
            <div class="input-group-prepend">
                <label for="statusCode" class="input-group-text">Status: </label>
            </div>
            <select name="statusCode" id="statusCode" [(ngModel)]="searchStatus">
                <option [ngValue]="null">All</option>
                <option [ngValue]="'PRIVATE'">Private (Only you can use)</option>
                <option [ngValue]="'PUBLIC'">Public (Anyone can use)</option>
              </select>
        </div>
        <button [disabled]="locations.length >= 10 && memberId !== 3" (click)="createLocation()">Create New</button>
        <div class="row input-group buffer">
            <input type="checkbox" name="syncLocation" id="syncLocation" [(ngModel)]="syncLocation" [ngModelOptions]="{standalone: true}"/>
            Sync map with your location:&nbsp;&nbsp;
            <button [ngClass]="{'roadmap': this.mapTypeId === 'roadmap', 'satellite': this.mapTypeId==='satellite'}" (click)="toggleMapType()">{{this.mapTypeId === 'roadmap'?'RoadMap':'Satellite'}}</button>    
    
        </div>
    </div>
    </form>
    <div class="container" *ngIf="this.currentPosition == null">Please wait... finding your current location.</div>
    <div class="container" *ngIf="this.currentPosition != null">
        <div *ngIf="editLocation == null" class="row">
            <table style="width:100%">
                <tr>
                    <th style="width:20%">Image</th>
                    <th style="width:40%">Clue Name</th>
                    <th style="width:40%">Clue Hint</th>
                </tr>
                <tr *ngIf="locations != null && locations.length === 0">
                    <td colspan="3">No matches found</td>
                </tr>
                <tr *ngFor="let location of locations | filter: 'statusCode': searchStatus | filterLocation: latitude: longitude: searchRadius"  (click)="selectLocation(location)">
                    <td style="vertical-align: top;">
                        <img src="{{location.url || '../../assets/user.png'}}">
                    </td>
                    <td style="vertical-align: top;">
                        {{ location.name }}
                    </td>
                    <td style="vertical-align: top;">
                        {{ location.hint}}
                    </td>
                </tr>
            </table>
        </div>
        <div *ngIf="editLocation != null">
            <form #editForm name="editForm">
                <div class="row">
                    <div class="col"><button form="editForm" class="btn btn-success" (click)="save()">Save</button></div>
                    <div class="col"><button form="editForm" class="btn btn-warning" (click)="cancel()">Cancel</button></div>
                    <div class="col"><button form="editForm" [disabled]="this.editLocation.id === 0" class="btn btn-danger" (click)="delete()">Delete</button></div>
                </div>
                <div class="row input-group">
                    <div class="input-group-prepend">
                        <label for="name" class="input-group-text">Name:</label>
                      </div>
                      <input type="textbox" class="form-control" name="name" id="name" [(ngModel)]="editLocation.name" 
                        placeholder="What to call your location">
                </div>
                <div class="row input-group">
                    <div class="input-group-prepend">
                        <label for="statusCode" class="input-group-text">Status:</label>
                      </div>
                      <select name="statusCode" id="statusCode" [(ngModel)]="editLocation.statusCode">
                        <option [ngValue]="'PRIVATE'">Private (Only you can use)</option>
                        <option [ngValue]="'PUBLIC'">Public (Anyone can use)</option>
                      </select>
                </div>
                <div class="row" style="border: 1px; border-color: black; border-style: solid;">
                    <div class="col col-10">
                        <div class="row input-group">
                            <div class="input-group-prepend">
                                <label for="url" class="input-group-text">Image:</label>
                            </div>
                            <input type="textbox" class="form-control" name="url" id="url" [(ngModel)]="editLocation.url" 
                                placeholder="Image to display on map">
                        </div>
                        <div class="row" style="padding-left: 50px;">
                            <input type="file" ng2FileSelect [uploader]="urlUploader"/>
                        </div>
                    </div>
                    <div class="col col-2">
                        <img *ngIf="editLocation.url" src="{{editLocation.url || '../../assets/user.png'}}"
                        [ngClass]="{'fullScreen': imageFullscreen === editLocation.url}" (click)="toggleImage(editLocation.url)">
                    </div>
                </div>                
                <div class="row input-group">
                    <div class="input-group-prepend">
                        <label for="hint" class="input-group-text">Clue Hint:</label>
                      </div>
                      <input type="textbox" class="form-control" name="hint" id="hint" [(ngModel)]="editLocation.hint" 
                        placeholder="Text hint to help them find it">
                </div>
                <div class="row input-group">
                    <div class="input-group-prepend">
                        <label for="code" class="input-group-text">Code:</label>
                      </div>
                      <input type="textbox" class="form-control" name="code" id="code" [(ngModel)]="editLocation.code" 
                        placeholder="A code to find at the location">
                </div>
                <div class="row input-group" *ngIf="editLocation.code != null && editLocation.code.length > 0">
                    <div class="input-group-prepend">
                        <label for="codeHint" class="input-group-text">Code Hint:</label>
                      </div>
                      <input type="textbox" class="form-control" name="codeHint" id="codeHint" [(ngModel)]="editLocation.codeHint" 
                        placeholder="A clue to find the code">
                </div>
                <div class="row" *ngIf="editLocation.code != null && editLocation.code.length > 0" style="border: 1px; border-color: black; border-style: solid;">
                    <div class="col col-10">
                        <div class="row input-group">
                            <div class="input-group-prepend">
                                <label for="codeUrl" class="input-group-text">Code Image:</label>
                            </div>
                            <input type="textbox" class="form-control" name="codeUrl" id="codeUrl" [(ngModel)]="editLocation.codeUrl" 
                                placeholder="Image to display on map">
                        </div>
                        <div class="row" style="padding-left: 50px;">
                            <input type="file" ng2FileSelect [uploader]="codeUrlUploader"  />
                        </div>
                    </div>
                    <div class="col col-2">
                        <img *ngIf="editLocation.codeUrl" src="{{editLocation.codeUrl || '../../assets/user.png'}}"
                            [ngClass]="{'fullScreen': imageFullscreen === editLocation.codeUrl}" (click)="toggleImage(editLocation.codeUrl)">
                    </div>
                </div>
                <div class="row input-group">
                    <div class="input-group-prepend">
                        <label for="distanceCheck" class="input-group-text">Distance Check (meters):</label>
                      </div>
                      <input type="textbox" class="form-control" name="distanceCheck" id="distanceCheck" 
                        [(ngModel)]="editLocation.distanceCheck" placeholder="How close they need to be (in meters)"
                        (change)="setMapZoom()">
                </div>
                
                <!-- <div class="row input-group">
                    <div class="input-group-prepend">
                        <label for="latitude" class="input-group-text">Latitude:</label>
                      </div>
                      <input type="textbox" class="form-control" name="latitude" id="latitude" [readOnly]="true" [ngModel]="editLocation.latitude">
                </div>
                <div class="row input-group">
                    <div class="input-group-prepend">
                        <label for="longitude" class="input-group-text">Longitude:</label>
                      </div>
                      <input type="textbox" class="form-control" name="longitude" id="longitude" [readOnly]="true" [ngModel]="editLocation.longitude">
                </div> -->
            </form>
        </div>
    </div>
    <agm-map *ngIf="this.currentPosition != null && locations != null"
        [mapTypeId]="mapTypeId"
        (mapClick)="setCoordinates($event)"
        [clickableIcons]=false
        [gestureHandling]="'greedy'"
        [zoom]="mapZoom"
        [latitude]="this.latitude"
        [longitude]="this.longitude">
        <agm-circle *ngIf="editLocation!=null" 
            (circleClick)="setCoordinates($event)"
            [latitude]="editLocation.latitude"
            [longitude]="editLocation.longitude"
            [fillOpacity] = "0.2"
            [fillColor] = "'green'"
            [radius]="parseFloat(editLocation.distanceCheck?editLocation.distanceCheck:50)">
        </agm-circle>
        <agm-marker 
            [latitude]="this.currentPosition.coords.latitude" 
            [longitude]="this.currentPosition.coords.longitude"
            [label]="'Current Position'">
        </agm-marker>
        <agm-marker *ngIf="this.editLocation != null"
            [latitude]="this.editLocation.latitude"
            [longitude]="this.editLocation.longitude"
            [label]="'New Marker'"
            [iconUrl]='{"url": this.editLocation.url,"scaledSize": {"height": 30, "width": 30}}'>
    
        </agm-marker>
        <agm-marker *ngFor="let location of locations |filter: 'statusCode': searchStatus | filterLocation: latitude: longitude: searchRadius" 
            [latitude]="location.latitude" 
            [longitude]="location.longitude"
            [iconUrl]='{"url": location.url,"scaledSize": {"height": 30, "width": 30}}'
            [animation]="'DROP'">
        </agm-marker>
    </agm-map>
</block-ui>