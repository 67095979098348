import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClueChase } from 'src/app/_models/clueChase';
import { Location } from 'src/app/_models/location';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Link } from 'src/app/_models/link';
import { ClueChaseService } from 'src/app/_services/clueChase.service';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {

  @Input() public clueChase: ClueChase;
  @Input() public position: any;

  locations: Location[];
  modalTitle: string = "Find Clue Location";
  searchRadius: number = 1000;
  searchText: string = "";

  constructor(public activeModal: NgbActiveModal,
    private alertify: AlertifyService,
    private clueChaseService: ClueChaseService) { }

  ngOnInit() {
    this.clueChaseService.locations(this.clueChase.id).subscribe(result => {
      this.locations = result;
    }, error => {
      this.alertify.error(error);
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  selectLocation(location: Location) {
    this.activeModal.close(location);
  }

  asTheCrowFlies(currentPosition: any, x2: number, y2: number) {
    var result = 0;
    var x1: number = currentPosition.coords.latitude;
    var y1: number = currentPosition.coords.longitude;
    const RADIANS: number = 180 / 3.14159265;
    const METRES_IN_MILE: number = 1609.34;
    
    if (x1 == x2 && y1 == y2) {
      result = 0;
    
    } else {
      // Calculating Distance between Points
      var lt1 = x1 / RADIANS;
      var lg1 = y1 / RADIANS;
      var lt2 = x2 / RADIANS;
      var lg2 = y2 / RADIANS;
    
      // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
      result = (3958.8 * METRES_IN_MILE) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
    }
    return Math.floor(result);
  }
}
