import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { WishlistMemberService } from '../_services/wishlistMember.service';
import { AlertifyService } from '../_services/alertify.service';
import { Route } from '@angular/compiler/src/core';

@Injectable({
  providedIn: 'root'
})
export class WishlistGuard implements CanActivate {

  constructor(private authService: AuthService,
              private wishlistMemberService: WishlistMemberService,
              private router: Router,
              private alertify: AlertifyService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn && this.authService.decodedToken != null;
    const isConfirmed = this.authService.isConfirmed();
    if (isLoggedIn && isConfirmed) {
      return true;
    }

    if (!isLoggedIn) {
      this.alertify.error('You must sign in before accessing the wishlist!');
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    console.log(state.url);
    if (state.url.startsWith('/wishlist/invitation/')) {
      const key = state.url.substring(21);
      this.wishlistMemberService.confirmWishlistInvitationLogin(key).subscribe(() => {
        this.alertify.success('Confirmed Wishlist Login');
        this.authService.setConfirmLogin();
        return true;
      }, error => {
        this.alertify.error(error);
      });
    } else if (state.url.startsWith('/member/parent/invitation/')) {
      const key = state.url.substring(26);
      this.authService.confirmParentInvitationLogin(key).subscribe(() => {
        this.alertify.success('Confirmed Parent Login');
        this.authService.setConfirmLogin();
        return true;
      }, error => {
        this.alertify.error(error);
      });
    } else if (state.url.startsWith('/secretSantaInvitation/')) {
      const key = state.url.substring(23);
      this.wishlistMemberService.confirmSecretSantaInvitationLogin(key).subscribe(() => {
        this.alertify.success('Confirmed Secret Santa Login');
        this.authService.setConfirmLogin();
        return true;
      }, error => {
        this.alertify.error(error);
      });
    } else {
      this.alertify.error('You must first confirm your Login!');
      this.router.navigate(['member/edit'], { });
    }
    return false;
  }

}
