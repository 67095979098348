<div class="form-group" *ngIf="(editMode && showAction) || (clueChaseLocation.location!=null && clueChaseLocation.location.found && clueChaseLocation.location.code != null && (clueChaseLocation.location.codeResponse !== clueChaseLocation.location.code || clueChaseLocation.location.showAction))">
    <div class="row">
        <div class="col font-weight-bold">Use the hint below to locate the correct response to progress to the next clue</div>
    </div>
    <div class="row">
        <div *ngIf="clueChaseLocation.location.codeUrl" class="col-sm-4">
            <img [ngClass]="{'fullScreen': clueChaseLocation.location.codeUrl === showImage}" (click)="setShow(clueChaseLocation.location.codeUrl)"
                class="card-img-top img-thumbnail" src="{{clueChaseLocation.location.codeUrl}}" alt="{{clueChaseLocation.location.codeHint}}"></div>
        <div class="col-sm-8">{{clueChaseLocation.location.codeHint}}</div>
    </div>
    <div class="row" *ngIf="editMode">
        Answer: {{ clueChaseLocation.location.code }}
    </div>
    <div class="row" *ngIf="!editMode && clueChaseLocation.location.code !== clueChaseLocation.location.codeResponse">
        <div class="col">
            <input type="text" class="form-control" id="codeResponse" 
            required [(ngModel)]="clueChaseLocation.location.codeResponse" (ngModelChange)="changedResponse($event)"></div>
    </div>
    <div class="row" *ngIf="!editMode || clueChaseLocation.location.code === clueChaseLocation.location.codeResponse">
        <div class="col">
            {{clueChaseLocation.location.codeResponse}}
        </div>
    </div>
</div>
