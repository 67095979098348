import { Component, OnInit } from '@angular/core';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { WishlistSendInvitation } from 'src/app/_models/wishlistSendInvitation';
import { AuthService } from 'src/app/_services/auth.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { WishlistInvitation } from 'src/app/_models/wishlistInvitation';
import { Router } from '@angular/router';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { WishlistMemberEditResolver } from 'src/app/_resolvers/wishlist-edit.resolver';
import { SuggestedInvitation } from 'src/app/_models/suggestedInvitation';

@Component({
  selector: 'app-wishlist-invitations',
  templateUrl: './wishlist-invitations.component.html',
  styleUrls: ['./wishlist-invitations.component.css']
})

export class WishlistInvitationsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  newInvitation: WishlistInvitation;
  wishlistInvitations: WishlistInvitation[] = [];
  suggestedInvitations: SuggestedInvitation[] = [];
  currentWishlistMember: WishlistMember;
  myEmails = new Array(0);

  constructor(private wishlistMemberService: WishlistMemberService,
              private alertify: AlertifyService,
              public authService: AuthService,
              private router: Router) { }

  ngOnInit() {
    this.newInvitation = JSON.parse(JSON.stringify({memberId: this.authService.currentMember.id}));
    this.blockUI.start('Loading invitations');

    this.wishlistMemberService.getWishlistMember(this.authService.currentMember.id).subscribe(wishlistMember => {
      this.currentWishlistMember = wishlistMember;
      this.currentWishlistMember.member.logins.map(login => {
        this.myEmails.push(login.email);
      });
      this.wishlistMemberService.getInvitations().subscribe(invitations => {
        this.wishlistInvitations = invitations;
        this.wishlistMemberService.getWishlistMembers().subscribe(wishlistMembers => {
          const existingEmails: string[] = [];
          // add my emails
          this.currentWishlistMember.member.logins.forEach(login => {
            if (existingEmails.findIndex(existingEmail => existingEmail === login.email) === -1) {
              existingEmails.push(login.email);
            }
          });
          // add my existing friends
          this.currentWishlistMember.wishlistMemberAccess.forEach(wishlistMemberAccess => {
            wishlistMemberAccess.wishlistMember.member.logins.forEach(login => {
              if (existingEmails.findIndex(existingEmail =>
                existingEmail === login.email) === -1) {
                  existingEmails.push(login.email);
                }
            })
          });
          // add my existing invitations
          this.wishlistInvitations.forEach(wishlistInvitation => {
            if (existingEmails.findIndex(existingEmail =>
              existingEmail === wishlistInvitation.email) === -1) {
              existingEmails.push(wishlistInvitation.email);
            }
            if (wishlistInvitation.wishlistMember !== null) {
              wishlistInvitation.wishlistMember.member.logins.forEach(login => {
                if (existingEmails.findIndex(existingEmail =>
                  existingEmail === login.email) === -1) {
                    existingEmails.push(login.email);
                  }
              });
            }
          });
          // Go through my existing wishlistMembers, and look for THEIR wishlist members that I might want to send an invitation to
          // that I haven't already...
          wishlistMembers.filter(notMyWishlistMember => notMyWishlistMember.id !== this.currentWishlistMember.id).map(notMyWishlistMember => {
            notMyWishlistMember.wishlistMemberAccess.forEach(wishlistMemberAccess => {
              var emailList: string[] = ['0'];
              wishlistMemberAccess.wishlistMember.member.logins.forEach(login => {
                if (emailList.findIndex(value => value === login.email) < 0)
                {
                  emailList.push(login.email);
                }
              });
              // don't suggest Invitations that we already have
              const found = emailList.some(r=> existingEmails.indexOf(r) >= 0);
              if (!found) {
                const suggestedInvitation = this.suggestedInvitations.find(si => si.wishlistMemberID === wishlistMemberAccess.wishlistMemberId);
                if (suggestedInvitation) {
                  // already have this suggestion, so just add this person to the suggestion's commonMembers
                  suggestedInvitation.commonMembers.push(notMyWishlistMember);
                } else {
                  // create a new suggestion
                  const newSuggestedInvitation: SuggestedInvitation = {
                    wishlistMemberID: wishlistMemberAccess.wishlistMemberId,
                    wishlistMember: wishlistMemberAccess.wishlistMember,
                    commonMembers: [],
                    commonMembersList: ''
                  };
                  // add this person as the first common member
                  newSuggestedInvitation.commonMembers.push(notMyWishlistMember);
                  this.suggestedInvitations.push(newSuggestedInvitation);
                }
              }
            })

            // build the list of names from the list of common members
            this.suggestedInvitations.map(invitation => {
              invitation.commonMembersList = invitation.commonMembers.map(common => common.member.knownAs).join(', ');
          });
          // console.log('Suggested:' + this.suggestedInvitations);
          // console.log('Existing:' + this.wishlistInvitations);
        }, error => {
          this.alertify.error(error);
        });
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
      });

    })
  }


  sendSuggestedInvitation(suggestedInvitation: SuggestedInvitation) {
    const wishlistInvitation: WishlistInvitation = JSON.parse(
      JSON.stringify(
        {
          email: suggestedInvitation.wishlistMember.member.logins.find(l => l.source === 'wyssweb').email
        }
      )
    );
    this.sendInvitation(wishlistInvitation);
    return false;
  }

  sendInvitation(send: WishlistInvitation) {
    this.blockUI.start('Sending invitation to ' + send.email);
    this.wishlistMemberService.sendInvitation(send).subscribe(invitation => {
      const index = this.wishlistInvitations.findIndex( wi => wi.id === invitation.id );
      if (index < 0) {
        this.wishlistInvitations.push(invitation);
      } else {
        this.wishlistInvitations[index] = invitation;
      }
      this.alertify.success('Wishlist invitation has been sent to ' + invitation.email);
      const suggestedInvitationIndex = this.suggestedInvitations.findIndex(si =>
        si.wishlistMember.member.logins.find(l => l.source === 'wyssweb').email === invitation.email);

      if (suggestedInvitationIndex > -1) {
        this.suggestedInvitations.splice(suggestedInvitationIndex, 1);
      }
      this.blockUI.stop();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
    return false;
  }

  deleteInvitation(invitation: WishlistInvitation) {
    this.alertify.confirm('Are you sure you want to delete the invitation to ' + invitation.email + '?', () => {
      this.blockUI.start('Deleting invitation to ' + invitation.email);
      this.wishlistMemberService.deleteInvitation(invitation).subscribe(() => {
        const index = this.wishlistInvitations.findIndex( wi => wi.id === invitation.id );
        this.wishlistInvitations.splice(index, 1);
        this.alertify.success('Invitation to ' + invitation.email + ' deleted succecssfully');
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
    });

    return false;
  }

  removeInvitation(invitation: WishlistInvitation) {
    this.alertify.confirm('Are you sure you want to delete the invitation from ' + invitation.wishlistMember.member.knownAs + '?', () => {
      this.blockUI.start('Deleting invitation to ' + invitation.email);
      this.wishlistMemberService.deleteInvitation(invitation).subscribe(() => {
        const index = this.wishlistInvitations.findIndex( wi => wi.id === invitation.id );
        this.wishlistInvitations.splice(index, 1);
        this.alertify.success('Invitation to ' + invitation.email + ' deleted succecssfully');
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
    });

    return false;
  }

  respondInvitation(invitation: WishlistInvitation) {
    this.router.navigate(['wishlist/invitation/' + invitation.key]);
  }

  hideInvitation(invitation: WishlistInvitation) {
    this.alertify.message('Sorry, I haven\'t implemented this just yet.  Not sure if it\'s needed...');
  }

  spliceLoginEmails(wishlistMember: WishlistMember) {
    return wishlistMember.member.logins.map(login => login.email);
  }
}
