<form #editForm name="mapForm">
<div class="row input-group buffer">
    <div class="col" *ngIf="mapPosition!=null">
        Display:&nbsp;&nbsp;
        <button [ngClass]="{'roadmap': this.mapType === 'roadmap', 'satellite': this.mapType==='satellite'}" (click)="toggleMapType()">{{this.mapType === 'roadmap'?'RoadMap':'Satellite'}}</button>    
    </div>
    <div *ngIf="showTrackType===true" class="col">
        Tracking:&nbsp;&nbsp;
        <button [ngClass]="{'poll': this.trackType === 'poll', 'watch': this.trackType === 'watch'}" (click)="toggleTrackType()">{{this.trackType === 'poll'?'Poll':'Watch'}}</button>
    </div>
</div>
</form>
<agm-map *ngIf="mapPosition!=null"
    [mapTypeId]="mapType"
    [clickableIcons]=false
    [gestureHandling]="'greedy'"
    [zoom]="zoom"
    [latitude]="mapPosition.coords.latitude"
    [longitude]="mapPosition.coords.longitude">
    <agm-circle [latitude]="mapPosition.coords.latitude"
        [longitude]="mapPosition.coords.longitude"
        [fillOpacity] = "0.2"
        [fillColor] = "'green'"
        [radius]="circleRadius"></agm-circle>
    <agm-marker *ngFor="let clueChaseLocation of iterateLocations()" 
        [latitude]="clueChaseLocation.location.latitude" 
        [longitude]="clueChaseLocation.location.longitude"
        [iconUrl]='{"url": clueChaseLocation.location.url,"scaledSize": {"height": 30, "width": 30}}'
        [animation]="'DROP'">
    </agm-marker>
</agm-map>
