import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Member } from '../_models/member';
import { WishlistMember } from '../_models/wishlistMember';
import { Gift } from '../_models/gift';
import { GiftAction } from '../_models/giftAction';
import { map } from 'rxjs/operators';
import { WishlistInvitation } from '../_models/wishlistInvitation';
import { InvitationResult } from '../_models/invitationResult';
import { WishlistSendInvitation } from '../_models/wishlistSendInvitation';
import { SecretSanta } from '../_models/secretSanta';
import { SecretSantaInvitation } from '../_models/secretSantaInvitation';

@Injectable({
  providedIn: 'root'
})
export class WishlistMemberService {

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  createWishlistMember(memberId: number, birthMonth: number, birthDom: number) {
    return this.http.post(this.baseUrl + 'wishlist/create', {memberId, birthMonth, birthDom});
  }

  getWishlistMembers(): Observable<WishlistMember[]> {
    return this.http.get<WishlistMember[]>(this.baseUrl + 'wishlist');
  }

  getWishlistMember(id: number): Observable<WishlistMember> {
    return this.http.get<WishlistMember>(this.baseUrl + 'wishlist/' + id);
  }

  updateWishlistMember(id: number, wishlist: WishlistMember) {
    return this.http.put(this.baseUrl + 'wishlist/' + id, wishlist);
  }

  createGift(fromMemberId: number, gift: Gift) {
    return this.http.post<Gift>(this.baseUrl + 'gift/create/' + fromMemberId, gift);
  }

  updateGift(gift: Gift) {
    return this.http.post<Gift>(this.baseUrl + 'gift/update', gift);
  }

  deleteGift(gift: Gift) {
    return this.http.post(this.baseUrl + 'gift/delete/' + gift.id, {});
  }

  giveGift(gift: Gift) {
    return this.http.post<WishlistMember>(this.baseUrl + 'wishlist/updatefrom/' + gift.id, { command: 'give', anonymous: gift.anonymous});
  }

  takebackGift(gift: Gift) {
    return this.http.post<WishlistMember>(this.baseUrl + 'wishlist/updatefrom/' + gift.id, { command: 'takeback', anonymous: gift.anonymous});
  }

  applyAnonymous(gift: Gift) {
    return this.http.post<WishlistMember>(this.baseUrl + 'wishlist/anonymous/' + gift.id, gift);
  }

  sendInvitation(invitation: WishlistInvitation) {
    return this.http.post<WishlistInvitation>(this.baseUrl + 'wishlist/invitation/send', invitation);
  }

  deleteInvitation(invitation: WishlistInvitation) {
    return this.http.post(this.baseUrl + 'wishlist/invitation/delete', invitation);
  }

  getInvitation(key: string) {
    return this.http.get<WishlistInvitation>(this.baseUrl + 'wishlist/invitation/' + key, {});
  }

  getInvitations() {
    return this.http.get<WishlistInvitation[]>(this.baseUrl + 'wishlist/invitations', {});
  }

  acceptInvitation(invitation: WishlistInvitation) {
    return this.http.post<InvitationResult>(this.baseUrl + 'wishlist/invitation/accept', invitation);
  }

  rejectInvitation(invitation: WishlistInvitation) {
    return this.http.post<InvitationResult>(this.baseUrl + 'wishlist/invitation/reject', invitation);
  }

  confirmWishlistInvitationLogin(key: string) {
    return this.http.put(this.baseUrl + 'confirm/wishlist/' + key, {});
  }

  removeWishlist(accessWishlistMemberId: number) {
    return this.http.post<WishlistMember[]>(this.baseUrl + 'wishlist/invitation/remove/' + accessWishlistMemberId, {});
  }

  getGiftsFrom(wishlistMemberId: number) {
    return this.http.get<Gift[]>(this.baseUrl + 'wishlist/gifts', {});
  }

  updateWishlistMemberGroup(wishlistMemberId: number, groupId: number) {
    return this.http.put(this.baseUrl + 'wishlist/updateWishlistMemberGroup/' + wishlistMemberId + '/' + groupId, {});
  }

  getSecretSantas() {
    return this.http.get<SecretSanta[]>(this.baseUrl + 'secretSanta', {});
  }

  getSecretSanta(secretSantaId: number) {
    return this.http.get<SecretSanta>(this.baseUrl + 'secretSanta/' + secretSantaId, {});
  }

  drawNamesFromAHat(secretSantaId: number) {
    return this.http.post<SecretSanta>(this.baseUrl + 'secretSanta/drawNamesFromHat/' + secretSantaId, {});
  }

  respondSecretSantaInvitation(secretSanta: SecretSanta, response: boolean) {
    return this.http.post<InvitationResult>(this.baseUrl + 'secretSanta/secretSantaInvitation/' + secretSanta.id + '/' + response, {});
  }

  updateSecretSanta(secretSanta: SecretSanta) {
    return this.http.post<SecretSanta>(this.baseUrl + 'secretSanta', secretSanta);
  }

  sendSecretSantaInvitations(secretSantaId: number, emails: string[]) {
    return this.http.post<SecretSantaInvitation[]>(this.baseUrl + 'secretSanta/sendSecretSantaInvitations/' + secretSantaId, emails);
  }

  deleteSecretSanta(secretSantaId: number) {
    return this.http.delete(this.baseUrl + 'secretSanta/' + secretSantaId);
  }

  deleteSecretSantaInvitation(secretSantaId: number, secretSantaInvitationId: number) {
    return this.http.delete(this.baseUrl + 'secretSanta/secretSantaInvitation/' + secretSantaId + '/' + secretSantaInvitationId);
  }

  resendSecretSantaInvitation(secretSantaId: number, secretSantaInvitationId: number) {
    return this.http.put<SecretSantaInvitation>(this.baseUrl + 'secretSanta/secretSantaInvitation/' 
      + secretSantaId + '/' + secretSantaInvitationId, {});
  }

  getSecretSantaInvitationFromKey(key: string) {
    return this.http.get<number>(this.baseUrl + 'secretSanta/secretSantaKey/' + key, {});
  }

  confirmSecretSantaInvitationLogin(key: string) {
    return this.http.put(this.baseUrl + 'secretSanta/confirm/secretSanta/' + key, {});
  }

}
