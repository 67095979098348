import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ClueChase } from 'src/app/_models/clueChase';
import { ClueChaseLocation } from 'src/app/_models/clueChaseLocation';
import { Location } from 'src/app/_models/location';
import { ClueChaseResolver } from 'src/app/_resolvers/clue-chase.resolver';
import { AlertifyService } from 'src/app/_services/alertify.service';

import { ClueChaseService } from 'src/app/_services/clueChase.service';
import { SortPipe } from 'src/app/_pipes/sortPipe';
import { AuthService } from 'src/app/_services/auth.service';
import { Member } from 'src/app/_models/member';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationSearchComponent } from '../location-search/location-search.component';

@Component({
  selector: 'app-my-games',
  templateUrl: './my-games.component.html',
  styleUrls: ['./my-games.component.scss']
})

export class MyGamesComponent implements OnInit {

  member: Member;
  searchRadius: number = 5000;
  clueChases: ClueChase[];
  newClueChase: ClueChase = <ClueChase> {}
  editClueChase: ClueChase;
  mapPosition = null;
  position = null;
  circleRadius = 200;
  mapZoom = 15;
  addLocationId: number = null;
  suggestLocations: Location[];
  sortPipe: SortPipe = new SortPipe();
  isDirty: boolean = false;
  trackType: string = 'watch';
  watchId: number;

  @Input() refresh: boolean;

  constructor(private route: ActivatedRoute
              , private clueChaseService: ClueChaseService
              , private alertify: AlertifyService
              , private authService: AuthService,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.clueChaseService.myGames().subscribe(clueChases => {
      this.clueChases = clueChases;
    }, error => {
      this.alertify.error(error);
    });

    this.member = this.authService.currentMember;
    setInterval(() => { this.findMe(); }, 3000);
    this.trackMe();
  }

  ngOnDestroy() {
    if (this.watchId > 0) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }

  findMe() {
    if (this.trackType === 'poll') {
      if (navigator.geolocation) {
        console.log('findMe');
        if (this.position == null) {
            navigator.geolocation.getCurrentPosition((position) => {
            this.position = position;
            navigator.geolocation.clearWatch(this.watchId);
            this.watchId = 0;
          }), function error(msg) {alert('Please enable your GPS position feature.');},
          {maximumAge:10000, timeout:3000, enableHighAccuracy: true};
        }
      }
    }   
  }

  trackMe() {
    // https://medium.com/@balramchavan/display-and-track-users-current-location-using-google-map-geolocation-in-angular-5-c259ec801d58
    if (navigator.geolocation) {
      this.watchId = navigator.geolocation.watchPosition((position) => {
          //console.log('trackMe');
          if (this.position == null) {
            this.mapPosition = position;
            navigator.geolocation.clearWatch(this.watchId);
            this.watchId = 0;
          }
        }
      ) , function error(msg) {
            alert('Please enable your GPS position feature.');
          }
        , {maximumAge:10000, timeout:10000, enableHighAccuracy: true};
    }
  }
  
  locationMethodChanged(method: string) {
    this.trackType = method;
  }

  updateDate(eventDate) {
    this.editClueChase.eventDate = eventDate;
  }
  
  createGame() {
    this.editClueChase = <ClueChase> { id: 0, clueChaseLocations: [], statusCode: "PENDING" };
  }

  selectGame(clueChase: ClueChase) {
    this.resetMapView(clueChase);
    this.editClueChase = clueChase;
    this.editClueChase.clueChaseLocations = this.sortPipe.transform(this.editClueChase.clueChaseLocations, 'sortOrder');
  }

  addLocation() {
    const modalRef = this.modalService.open(LocationSearchComponent);
    modalRef.componentInstance.clueChase = this.editClueChase;
    modalRef.componentInstance.position = (this.mapPosition == null)?this.position: this.mapPosition;
    modalRef.result.then((location: Location) => {
      this.clueChaseService.addLocation(this.editClueChase.id, location.id).subscribe(clueChaseLocation => {
        this.editClueChase.clueChaseLocations.push(clueChaseLocation);
        this.resetMapView(this.editClueChase);
        this.isDirty = true;
      }, error => {
        this.alertify.error(error);
      });
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }

  updateGame() {
    if (this.editClueChase.id === 0) {
      this.clueChaseService.createGame(this.editClueChase).subscribe(result=>{
        this.clueChases.push(result);
        this.editClueChase = null;
        this.mapPosition = null;
        this.isDirty = false;
      }, error => {
        this.alertify.error(error);
      });
    } else {
      this.clueChaseService.updateGame(this.editClueChase).subscribe(result => {
        this.editClueChase = null;
        this.mapPosition = null;
        this.isDirty = false;
      }, error => {
        this.alertify.error(error);
      });
    }
  }

  cancelUpdate() {
    this.editClueChase = null;
    this.mapPosition = null;
  }

  asTheCrowFlies(currentPosition: any, x2: number, y2: number) {
    var result = 0;
    var x1: number = currentPosition.coords.latitude;
    var y1: number = currentPosition.coords.longitude;
    const RADIANS: number = 180 / 3.14159265;
    const METRES_IN_MILE: number = 1609.34;
    
    if (x1 == x2 && y1 == y2) {
      result = 0;
    
    } else {
      // Calculating Distance between Points
      var lt1 = x1 / RADIANS;
      var lg1 = y1 / RADIANS;
      var lt2 = x2 / RADIANS;
      var lg2 = y2 / RADIANS;
    
      // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
      result = (3958.8 * METRES_IN_MILE) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
    }
    return result;
  }

  findCenter(clueChase: ClueChase)
  {
    let latitude = 0;
    let longitude = 0;
    var sumLatitude = 0;
    var sumLongitude = 0;

    clueChase.clueChaseLocations.forEach(clueChaseLocation=> {
      sumLatitude += clueChaseLocation.location.latitude;
      sumLongitude += clueChaseLocation.location.longitude;
    });

    latitude = sumLatitude/clueChase.clueChaseLocations.length;
    longitude = sumLongitude/clueChase.clueChaseLocations.length;

    return { coords: { latitude: latitude, longitude: longitude}};
  }

  getRadius(clueChase: ClueChase) {
    let radius = 0;
    clueChase.clueChaseLocations.forEach(clueChaseLocation=> {
      if (radius === 0) {
        radius = clueChaseLocation.location.distanceCheck;
      }
      let distance = this.asTheCrowFlies(this.mapPosition, clueChaseLocation.location.latitude, clueChaseLocation.location.longitude);

      if (distance > radius) {
        radius = distance;
      }
    })

    return radius;
  }

  getMapZoom() {
    let zoom = Math.round(14-Math.log(this.circleRadius/512)/Math.LN2);
    return zoom;
  }

  private resetMapView(clueChase: ClueChase) {
    if (clueChase.clueChaseLocations.length > 0) {
      this.mapPosition = this.findCenter(clueChase);
      this.circleRadius = this.getRadius(clueChase);
      clueChase.radius = Math.floor(this.circleRadius);
      clueChase.latitude = this.mapPosition.coords.latitude;
      clueChase.longitude = this.mapPosition.coords.longitude;
      this.mapZoom = this.getMapZoom();
    }
  }

  deleteGame() {
    if (this.editClueChase.id === 0) {
      this.cancelUpdate();
    }
    this.alertify.confirm('Are you sure you wish to delete ' + this.editClueChase.name + '?', () => {
      this.clueChaseService.deleteGame(this.editClueChase).subscribe((result) => {
        var deletedIndex = this.clueChases.findIndex(c => c.id === result);
        this.clueChases.splice(deletedIndex, 1);
        this.editClueChase = null;
      })
    }, 'Confirm Delete');
  }

  deleteClueChaseLocation(clueChaseLocation: ClueChaseLocation) {
    this.alertify.confirm('Are you sure you want to remove ' + clueChaseLocation.location.name + '?  You can re-add it if you change your mind', () => {
      this.clueChaseService.deleteClueChaseLocation(clueChaseLocation.id).subscribe((clueChaseLocations) => {
        this.editClueChase.clueChaseLocations = clueChaseLocations;
        this.resetMapView(this.editClueChase);
        this.isDirty = true;
      }, error => {
        this.alertify.error(error);
      });
      
    }, 'Confirm Remove Location');
    
  }

}
