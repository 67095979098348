import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { Month } from 'src/app/_models/month';
import { ActivatedRoute } from '@angular/router';
import { Member } from 'src/app/_models/member';

@Component({
  selector: 'app-wishlist-create',
  templateUrl: './wishlist-create.component.html',
  styleUrls: ['./wishlist-create.component.css']
})
export class WishlistCreateComponent implements OnInit {

  @Input() wishlistMember: WishlistMember;
  @Output() createdWishlistMember: EventEmitter<WishlistMember> = new EventEmitter<WishlistMember>();
  birthMonth: number;
  birthDom: number;
  member: Member;
  monthObjects = new Array<Month>({id: 1, days: 31, display: 'January'},
                                {id: 2, days: 29, display: 'February'},
                                {id: 3, days: 31, display: 'March'},
                                {id: 4, days: 30, display: 'April'},
                                {id: 5, days: 31, display: 'May'},
                                {id: 6, days: 30, display: 'June'},
                                {id: 7, days: 31, display: 'July'},
                                {id: 8, days: 31, display: 'August'},
                                {id: 9, days: 30, display: 'September'},
                                {id: 10, days: 31, display: 'October'},
                                {id: 11, days: 30, display: 'November'},
                                {id: 12, days: 31, display: 'December'});
  monthOptions = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
  dayList: string[];
  daysPerMonth = new Array(31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);

  constructor(private wishlistMemberService: WishlistMemberService,
              private authService: AuthService,
              private alertify: AlertifyService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.member = this.authService.currentMember;
    console.log(this.member);
  }

  createWishlist() {
    if (!this.birthMonth) {
      this.alertify.error('You must select the birth Month');
      return false;
    }
    if (!this.birthDom) {
      this.alertify.error('You must select the birth Day');
      return false;
    }

    const monthNumber = this.monthOptions.findIndex(m => m === this.birthMonth) + 1;
    let memberId = this.route.snapshot.params.id;
    
    if (!memberId || memberId === 0) {
      memberId = this.authService.currentMember.id;
    }

    this.wishlistMemberService.createWishlistMember(memberId, monthNumber, this.birthDom).subscribe(next => {
      this.wishlistMember = next as WishlistMember;
      this.alertify.success('Wishlist created successfully');
      this.createdWishlistMember.emit(this.wishlistMember);
    }, error => {
      this.alertify.error(error);
    });
  }

  compareOption(option1: string, option2: string): boolean {
    return option1 === option2;
  }

  setDayList() {
    const monthIndex = this.monthOptions.findIndex(m => m === this.birthMonth);
    this.dayList = [];
    for (let index = 0; index < this.daysPerMonth[monthIndex]; index ++) {
      const day = index + 1;
      this.dayList.push(day.toString());
    }
  }
}
