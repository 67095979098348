import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule, MatCheckboxModule } from '@angular/material'

import { SortPipe } from 'src/app/_pipes/sortPipe';
import { FilterPipe } from 'src/app/_pipes/filterPipe';
import { ExcludePipe } from 'src/app/_pipes/excludePipe';
import { NotNullPipe } from 'src/app/_pipes/notNullPipe';
import { FilterClueChaseLocation } from 'src/app/_pipes/filterClueChaseLocationPipe';
import { FilterLocation } from 'src/app/_pipes/filterLocationPipe';
import { FilterWildPipe } from 'src/app/_pipes/filterWildPipe';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  declarations: [SortPipe, FilterPipe, ExcludePipe, NotNullPipe,FilterClueChaseLocation, FilterLocation, FilterWildPipe],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    MatSliderModule, MatCheckboxModule,
    FileUploadModule
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    SortPipe, FilterPipe, ExcludePipe, NotNullPipe, FilterClueChaseLocation, FilterLocation,FilterWildPipe,
    MatSliderModule, MatCheckboxModule,
    FileUploadModule
  ]
})
export class SharedModule { }
