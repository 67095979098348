<form *ngIf='this.passwordReset' [formGroup]="resetForm" (ngSubmit)="resetPassword()">
  <h2 class="text-center text-primary">Sign Up</h2>
  <hr>
  <div class="form-group">
    <strong>Login Email:</strong>
    {{ this.passwordReset.login.email }}
  </div>

  <div class="form-group">
    <strong>Password</strong>
    <input type="password" class="form-control" 
      [ngClass]="{'is-invalid': resetForm.get('password').errors && resetForm.get('password').touched}"
      formControlName="password" placeholder="Password">
      <div class="invalid-feedback"
        *ngIf="resetForm.get('password').hasError('required')
          && resetForm.get('password').touched">
          Password is required
      </div>
      <div class="invalid-feedback"
        *ngIf="resetForm.get('password').hasError('minlength')
        && resetForm.get('password').touched">
          Password must be at least {{this.passwordMinLength}} characters
      </div>
      <div class="invalid-feedback"
        *ngIf="resetForm.get('password').hasError('maxlength')
        && resetForm.get('password').touched">
          Password cannot exceed [[this.passwordMaxLength]] characters
      </div>
  </div>
  <div class="form-group">
    <strong>Confirm Password</strong>
    <input type="password" class="form-control" 
      [ngClass]="{'is-invalid': resetForm.get('confirmPassword').hasError('required') 
        && resetForm.get('confirmPassword').touched
        || resetForm.get('confirmPassword').touched
        && resetForm.hasError('mismatch')}"
        formControlName="confirmPassword" placeholder="Confirm Password">
    <div class="invalid-feedback"
      *ngIf="resetForm.get('confirmPassword').hasError('required')
      && resetForm.get('confirmPassword').touched">
      Password is required
    </div>
    <div class="invalid-feedback"
      *ngIf="resetForm.hasError('mismatch')
      && resetForm.get('confirmPassword').touched">
      Passwords must match
    </div>
  </div>

  <div class="form-group text-center">
    <button class="btn btn-success" type="submit" [disabled]="!resetForm.valid">Reset</button>
    <!-- <button class="btn btn-default" type="button" (click)="cancel()">Cancel</button> -->
  </div>
</form>
