<div class="container">
  <h4>To start creating your wishlist, all we need is your birth Month and Day...</h4>
  <h5 *ngIf="this.member.provider">If you already have a wish list that you created with your email, you should stop here and log in with your email account.  Once you are logged in, edit your Profile and register with your social media.</h5>
  <form #wishlistForm="ngForm" id="wishlistForm" (ngSubmit)="createWishlist()" class="col-md-12">
    <div class="row">
      <div class="col-sm-12">
        <strong>Birth Month/Day</strong>
        <select name="birthMonth" [compareWith]="compareOption" [(ngModel)]="this.birthMonth" (change)="setDayList()">
          <option *ngFor="let option of monthOptions; let i = index;" [ngValue]="option">
              {{ monthObjects[i].display }}
          </option>
        </select>
        /
        <select name="birthDom" [compareWith]="compareOption" [(ngModel)]="this.birthDom">
          <option *ngFor="let option of dayList" [ngValue]="option">
              {{option}}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 submitButton">
        <button type="submit" class="btn btn-success" form="wishlistForm" [disabled]="(!wishlistForm.form.valid || !wishlistForm.dirty)">Submit</button>
      </div>
    </div>
  </form>
</div>