<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h2><a href="http://www.vivecraft.org/">ViveCraft</a></h2>
      ViveCraft is a VR add-on to Mojang's Java <a href="https://minecraft.net/">Minecraft</a>.  If you want to play on my server, just 
      follow the instructions in the <a href="http://www.vivecraft.org">ViveCraft</a> / <a href="http://www.vivecraft.org/downloads/">Downloads</a> link.
      <br>
      Once you've gotten everything installed, and you're in Minecraft, just select "Multiplayer", then "Add Server", then type wyssweb.com in to the Server Address.
      <br>
      <img style="width: 50%; padding: 10px;" src="../../../assets/vivecraft_multiplayer.PNG" alt="">
      <br>
      <br>
      I'm still testing the stability of the server - it's running on an Intel NUC
      <br>
      <br>
      If you want to join a voice-chat Discord channel, just send me an email at <a href="mailto:administrator@wyssweb.com">Wyssweb</a> (please fill in the subject and email body :)), and I'll send you the link.
    </div>
  </div>
</div>
