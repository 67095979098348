import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ClueChaseService } from 'src/app/_services/clueChase.service';
import { Location } from 'src/app/_models/location';
import { AuthService } from 'src/app/_services/auth.service';
import { FilterLocation } from 'src/app/_pipes/filterLocationPipe';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-location-maint',
  templateUrl: './location-maint.component.html',
  styleUrls: ['./location-maint.component.scss']
})
export class LocationMaintComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  memberId: number;
  locations: Location[];
  editLocation: Location = null;
  syncLocation: boolean = true;
  autoLocation: boolean = true;
  circleRadius: number = 500;
  searchRadius: number = 5000;
  searchStatus: string = null;
  latitude: number;
  longitude: number;
  mapZoom = 15;
  mapTypeId: string = 'roadmap';
  currentPosition: any = null;
  filterLocation: FilterLocation = new FilterLocation();
  watchId: number = 0;
  imageFullscreen: string = null;

  urlUploader: FileUploader;
  codeUrlUploader: FileUploader;
  baseUrl = environment.apiUrl;

  @Output() refreshClueChases: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private route: ActivatedRoute
    , private clueChaseService: ClueChaseService
    , private alertify: AlertifyService
    , private authService: AuthService) { }

  ngOnInit() {
    this.clueChaseService.myLocations().subscribe(locations => {
      this.locations = locations;
    }, error => {
      this.alertify.error(error);
    });

    this.memberId = this.authService.currentMember.id;

    //setInterval(() => { this.findMe(); }, 3000);
    this.trackMe();
  }
  
  ngOnDestroy() {
    if (this.watchId > 0) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }
    
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        //console.log(position);
        if (this.syncLocation === true && this.autoLocation === true) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.currentPosition = position;
            this.setMapZoom();
        }
      }), function error(msg) {this.alertify.message('Please enter the latitude, longitude.');},
      {maximumAge:10000, timeout:3000, enableHighAccuracy: true};
    }   
  }
  
  trackMe() {
    // https://medium.com/@balramchavan/display-and-track-users-current-location-using-google-map-geolocation-in-angular-5-c259ec801d58
    if (navigator.geolocation) {
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          if (this.syncLocation === true && this.autoLocation === true) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.setMapZoom();
            this.currentPosition = position;
          }
        }
      ) , function error(msg) {
            alert('Please enable your GPS position feature.');
          }
        , {maximumAge:10000, timeout:10000, enableHighAccuracy: true};
    }
  }
  
  initializeUrlUploader() {
    this.urlUploader = new FileUploader({
      url: this.baseUrl + 'clueChase/photo/create/' + this.editLocation.id + '/url',
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: true,
      maxFileSize: 10 * 1024 * 1024
    });

    this.urlUploader.onBeforeUploadItem = (file) => {
      this.blockUI.start('Uploading Image');
    }

    this.urlUploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.urlUploader.onSuccessItem = (item, response, status, header) => {
      if (response) {
        const res: Location = JSON.parse(response);
        this.editLocation.url = res.url;
        this.editLocation.urlPublicId = res.urlPublicId;
        this.refreshClueChases.emit(true);
      }
      this.blockUI.stop();
    };
  }

  initializeCodeUrlUploader() {
    this.codeUrlUploader = new FileUploader({
      url: this.baseUrl + 'clueChase/photo/create/' + this.editLocation.id + '/codeUrl',
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: true,
      maxFileSize: 10 * 1024 * 1024
    });

    this.codeUrlUploader.onBeforeUploadItem = (file) => {
      this.blockUI.start('Uploading Image');
    }

    this.codeUrlUploader.onAfterAddingFile = (file) => {
      file.withCredentials = false; 
    };

    this.codeUrlUploader.onSuccessItem = (item, response, status, header) => {
      if (response) {
        const res: Location = JSON.parse(response);
        this.editLocation.codeUrl = res.codeUrl;
        this.editLocation.codeUrlPublicId = res.codeUrlPublicId;
        this.refreshClueChases.emit(true);
      }
      this.blockUI.stop();
    };
  }

  findCenter()
  {
    var sumLatitude = 0;
    var sumLongitude = 0;
    var searchedLocations: Location[] = this.filterLocation.transform(this.locations, this.latitude, this.longitude, this.searchRadius);
    searchedLocations.forEach(location=> {
      sumLatitude += location.latitude;
      sumLongitude += location.longitude;
    });

    let latitude = sumLatitude/searchedLocations.length;
    let longitude = sumLongitude/searchedLocations.length;

    return { coords: { latitude: latitude, longitude: longitude}};
  }

  setMapZoom() {
    if (this.editLocation != null) {
      this.circleRadius = this.editLocation.distanceCheck;
    }
    else {
      this.circleRadius = this.searchRadius;
    }

    let zoom = Math.round(14-Math.log(this.circleRadius/512)/Math.LN2);

    this.mapZoom = zoom;
  }

  selectLocation(location:Location) {
    this.editLocation = JSON.parse(JSON.stringify(location));
    this.autoLocation = false;
    this.latitude = location.latitude;
    this.longitude = location.longitude;
    this.circleRadius = location.distanceCheck;
    this.setMapZoom();
    this.initializeUrlUploader();
    this.initializeCodeUrlUploader();
  }

  parseFloat(radius: any) {
    return parseFloat(radius);
  }

  delete() {
    this.alertify.confirm('Are you sure you want to delete ' + this.editLocation.name 
      + '?  This location will be removed from any existing games'
      , () => {
        this.clueChaseService.deleteLocation(this.editLocation).subscribe((locationId) => {
          var index = this.locations.findIndex(l => l.id === locationId);
          this.locations.splice(index, 1);
          this.cancel();
        }, error => {
          this.alertify.error(error);
        }
      )
    });
  }

  save() {
    let locationId = this.editLocation.id;
    if (locationId != null && locationId > 0) {
      this.clueChaseService.updateLocation(this.editLocation).subscribe(location => {
          let index = this.locations.findIndex(l => l.id === locationId);
          this.locations[index] = location;
          this.editLocation = null;
          this.refreshClueChases.emit(true);   
      }, error => {
        this.alertify.error(error);
      });
    }
    else {      
      this.clueChaseService.createLocation(this.editLocation).subscribe(location => {
        this.locations.push(location);
        this.editLocation = null;
      }, error => {
        this.alertify.error(error);
      });      
    }
    this.autoLocation = true;
    if (this.syncLocation === true) {
      this.latitude = this.currentPosition.coords.latitude;
      this.longitude = this.currentPosition.coords.longitude;
    }

    this.setMapZoom();
  }

  createLocation() {
    this.editLocation = <Location> { id: 0, latitude: this.latitude, longitude: this.longitude, distanceCheck: 100 };
    this.autoLocation = false;
    this.initializeUrlUploader();
    this.initializeCodeUrlUploader();
  }

  cancel() {
    this.editLocation = null;
    this.autoLocation = true;
    if (this.syncLocation === true) {
      this.latitude = this.currentPosition.coords.latitude;
      this.longitude = this.currentPosition.coords.longitude;
    }

    this.setMapZoom();
  }

  setCoordinates(position: any) {
    if (this.editLocation != null) {
      this.editLocation.latitude = position.coords.lat;
      this.editLocation.longitude = position.coords.lng;
    }

    this.latitude = position.coords.lat;
    this.longitude = position.coords.lng;
  }

  toggleMapType() {
    if (this.mapTypeId === 'roadmap') {
      this.mapTypeId = 'satellite';
    } else {
      this.mapTypeId = 'roadmap';
    }
  }

  toggleImage(url: string) {
    if (this.imageFullscreen == null) {
      this.imageFullscreen = url;
    }
    else {
      this.imageFullscreen = null;
    }
  }
}
