import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WishlistMember } from '../_models/wishlistMember';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WishlistMemberService } from '../_services/wishlistMember.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  currentWishlistMember: WishlistMember;
  members: WishlistMember[];
  currentMode = 'wishlists';
  wishlistGroupId = 0;
  returnUrl: string;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private alertify: AlertifyService,
              private wishlistMemberService: WishlistMemberService) { }

  ngOnInit() {
    const returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    if (returnUrl.startsWith('/secretSantaInvitation/')) {
      this.returnUrl = returnUrl;
    }

    const groupId = localStorage.getItem('wishlistGroupId') as any as number;
    if (groupId * 1 === 0) {
      this.wishlistGroupId = 0;
    } else if (groupId * 1 === 1) {
      this.wishlistGroupId = 1;
    } else if (groupId * 1 === 2) {
      this.wishlistGroupId = 2;
    } else if (groupId * 1 === 3) {
      this.wishlistGroupId = 3;
    }

    this.currentMode = localStorage.getItem('wishlistMode');
    if (this.currentMode === null) {
      this.currentMode = 'wishlists';
    }
    this.route.data.subscribe(data => {
      this.members = data.wishlistMembers;
      if (this.members == null) {
        this.members = [];
      }
      this.members.map(member => {
        if (member.memberID === this.authService.currentMember.id) {
          this.currentWishlistMember = member;
        }
      });
    });
  }

  setCurrentWishlistMember(result: WishlistMember) {
    console.log(result);
    this.currentWishlistMember = result;
    this.members.push(this.currentWishlistMember);
    if (this.returnUrl && this.returnUrl.length > 0) {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  setCurrentMode(mode: string) {
    this.currentMode = mode;
    localStorage.setItem('wishlistMode', mode);
  }

  setGroup(groupId: number) {
    this.wishlistGroupId = groupId;
    localStorage.setItem('wishlistGroupId', groupId.toString());
    return false;
  }

  removeWishlistMember(wishlistMembers: WishlistMember[]) {
    this.members = wishlistMembers;
  }

  // https://stackoverflow.com/questions/35662530/how-to-implement-drag-and-drop-in-angular2
  // https://stackoverflow.com/questions/47975237/html-5-drag-drop-with-angular-4
  drag(event) {
    event.dataTransfer.setData('id', event.target.id);
  }

  drop(event) {
    event.preventDefault();
    const groupId = event.target.id * 1;
    const wishlistMemberId = event.dataTransfer.getData('id') * 1;
    const wishlistMember = this.members.find(m => m.id === wishlistMemberId);
    if (wishlistMember.wishlistGroupId === groupId) {
      return false;
    }

    this.wishlistMemberService.updateWishlistMemberGroup(wishlistMemberId, groupId).subscribe(() => {
      wishlistMember.wishlistGroupId = groupId;
    }, error => {
      this.alertify.error(error);
    });
  }

  allowDrop(event) {
    event.preventDefault();
  }

}
