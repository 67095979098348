import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PlayGameComponent } from './play-game/play-game.component';
import { MapDisplayComponent } from './map-display/map-display.component';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationActionComponent } from './location-action/location-action.component';
import { BlockUIModule } from 'ng-block-ui';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MyGamesComponent } from './my-games/my-games.component';
import { LocationMaintComponent } from './location-maint/location-maint.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { MyClueChasesComponent } from './my-clue-chases/my-clue-chases.component';
import { BsDropdownModule, TabsModule, BsDatepickerModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [PlayGameComponent, MapDisplayComponent, LocationListComponent, LocationActionComponent, 
    MyGamesComponent, LocationMaintComponent, LocationSearchComponent, LocationSearchComponent, MyClueChasesComponent],
  imports: [
    CommonModule,
    SharedModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    BlockUIModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAYV8XK76MAyJaWMdhTDgPFveHuD_bqhG0'
    })
  ],entryComponents: [LocationSearchComponent]
})
export class ClueChaseModule { }
