<block-ui>
  <table *ngIf="this.wishlistMember && this.wishlistMember.myGifts?.length > 0 && this.currentWishlistMember != null" cellpadding="3" cellspacing="3">
    <tr>
      <td colspan="3">
        <input type="text" style="width: 100%;" placeholder="Type here to filter the list of gifts" (input)="applyGiftFilter($event)">
      </td>
    </tr>
    <tr *ngIf="this.searchValue && this.searchValue.length > 0" >
      <td colspan="3" class="error">
        Matches found for <span style="color:black;">{{ this.searchValue }}</span>: {{ this.filteredGifts.length}}
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <hr class="hline-bottom">
      </td>
    </tr>
    <ng-container *ngFor="let gift of this.filteredGifts">
      <tr>
        <td colspan="3">
          <p *ngIf="gift.toId === this.currentWishlistMember.id && gift.createdById === this.currentWishlistMember.id">
            <button class="btn btn-success" style="width:45%;" (click)="editGift(gift)">Edit</button>
            <button class="btn btn-danger" style="width:45%;" (click)="deleteGift(gift)">Delete</button>
          </p>
          <p *ngIf="gift.toId !== this.currentWishlistMember.id">
            <button *ngIf="gift.fromId == null" 
              class="btn btn-success" 
              style="width:30%;" 
              (click)="promptGive(gift)">Get this gift</button>
            <button *ngIf="gift.createdById == this.currentWishlistMember.id" 
              class="btn btn-primary" 
              style="width:30%;" 
              (click)="editGift(gift)">Edit this gift</button>
              <button *ngIf="gift.fromId != null && gift.fromId == this.currentWishlistMember.id" 
                class="btn btn-danger" 
                style="width:30%;" 
                (click)="promptTakeback(gift)">Changed my mind</button>
              <button *ngIf="gift.fromId != null && gift.fromId == this.currentWishlistMember.id && gift.anonymous" 
                class="btn btn-success" 
                style="width:30%;" 
                (click)="applyAnonymous(gift, false)">Show my name</button>
              <button *ngIf="gift.fromId != null && gift.fromId == this.currentWishlistMember.id && !gift.anonymous" 
                class="btn btn-success" 
                style="width:30%;" 
                (click)="applyAnonymous(gift, true)">Hide my name</button>
          </p>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <span *ngIf="gift.fromId != null && !gift.anonymous">Gotten by {{ gift.from.member.knownAs }}</span>
          <span *ngIf="gift.fromId != null && gift.anonymous">Gotten by Anonymous</span>
        </td>
      </tr>
      <tr *ngIf="gift.error && gift.error.length > 0">
        <td colspan="3" style="background-color: #ff0000; color: #ffffff;">
          {{ gift.error }}
        </td>
      </tr>
      <tr>
        <th>Description</th>
        <th>Is it likely?</th>
        <th>When?</th>
      </tr>
      <tr>
        <td>
          {{gift.description}}
        </td>
        <td>
          {{gift.expectation}}
        </td>
        <td>
          {{gift.event}}
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <ul>
            <li *ngFor="let link of gift.giftLinks">
              <a href="{{link.url}}" target="_blank" rel="noopener noreferrer">
                <i class="fa info-circle"></i>{{link.name}}
              </a>
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <hr class="hline-bottom">
        </td>
      </tr>
      </ng-container>
  </table>
  <button (click)="newGift()">New Gift </button>
</block-ui>