<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h2>Games</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <a href="return false;" (click)="laserduel();">Laser Duel</a>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <a href="return false;" (click)="vivecraft();">ViveCraft (VR/nonVR using ViveCraft)</a>
    </div>
  </div>
  <div *ngIf="false" class="row" style="background-color: lightgreen;">
    <div class="col-md-3">
      New Years 2020 FPS event!<br/><br/><br/>Game Time: 1/1/2020, 4pm - 6pm
    </div>
    <div class="col-md-9">
      <ol>
        <li>Install <a href="https://discordapp.com/download" target="_blank">Discord</a> (for voice-chat... you'll need a microphone, and a headset would not be amiss)</li>
        <li>Install <a href="https://store.steampowered.com/app/440/Team_Fortress_2/" target="_blank">Team Fortress 2</a> (free to play on Steam!)</li>
        <li>Call Dana (505-321-3253) if you have difficulty with either of those!</li>
        <li>Recommended: Test your Discord installation (and microphone/speakers!) by joining the channel (Call Dana for an invite)</li>
        <li>Once you're in Team Fortress 2, click "Find a Game", then click "Community Servers".  Look for the game named "Forlorn".</li>
        <li>If you can't find the game...<br/>
          a. you'll need to enable your in-game console. By default, this is turned off. To turn it on, boot up TF2 and open up your settings, From there, go to "Advanced" and then click the checkbox that says "enable developer console" click okay and now you can access the console whenever you want by pressing "`" (located below escape or above tab, to the left of 1 on most QWERTY keyboards)<br>
          <a href="https://cdn.gameserverkings.com/articles/bHXw6x/ingameconsole.jpg" target="_blank">Click image to enlarge</a><br/>
          <a href="https://cdn.gameserverkings.com/articles/bHXw6x/ingameconsole.jpg" target="_blank"><img style="width:30%;height:30%" src="https://cdn.gameserverkings.com/articles/bHXw6x/ingameconsole.jpg"></a><br/>
        b. Then press "`" to open the console. Once your console is open type in <span style="background-color: #c0c0c0;">connect wyssweb.com</span>, press enter (enter password m0rbr1n), and you'll be in!
        </li>
      </ol>
    </div>
  </div>
</div>
