import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';

@Component({
  selector: 'app-wishlist-card',
  templateUrl: './wishlist-card.component.html',
  styleUrls: ['./wishlist-card.component.css']
})
export class WishlistCardComponent implements OnInit {
  @Input() wishlistMember: WishlistMember;
  @Output() wishlistMemberRemoved: EventEmitter<WishlistMember[]> = new EventEmitter();
  currentMemberId: number;
  showDetail = false;
  constructor(private wishlistMemberService: WishlistMemberService,
              private authService: AuthService,
              private alertify: AlertifyService) {
    this.currentMemberId = authService.currentMember.id;
  }

  ngOnInit() {  }

  removeWishlistMember() {
    this.alertify.confirm('Are you sure you want to remove ' + this.wishlistMember.member.knownAs
      + '?  A new invitation will need to be sent and accepted to get them back.', () => {
      this.wishlistMemberService.removeWishlist(this.wishlistMember.id).subscribe(result => {
        this.wishlistMemberRemoved.emit(result);
      }, error => {
        this.alertify.error(error);
      });
    });
    return false;
  }

  removeChild(wishlistMember: WishlistMember) {
    this.alertify.message('You have access to ' + wishlistMember.member.knownAs + ' because you have access to their parent(s)');
    return false;
  }
}
