import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterWild',
    pure: false
})
export class FilterWildPipe implements PipeTransform {
    transform(items: any[], field: string, value: any): any[] {
        if (!items) { return []; }

        if (value.length == 0) {
            return items;
        }
        //console.log(items.filter(it => it['name'].includes(value)));
        if (Array.isArray(field)) {
            let returnValues = [];
            field.map(f => {
                console.log(items[0][f]);
                returnValues = returnValues.concat(items.filter(it => it[f].toLowerCase().includes(value)));
            });
            // tslint:disable-next-line: only-arrow-functions
            const result = returnValues.filter(function(item, pos) {
                return returnValues.indexOf(item) === pos;
            });
            return result;
        } else {
            return items.filter(it => it[field].toLowerCase().includes(value));
        }
    }
}
