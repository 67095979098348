import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { PasswordReset } from 'src/app/_models/passwordReset';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
  resetForm: FormGroup;
  passwordReset: PasswordReset;

  passwordMinLength = 6;
  passwordMaxLength = 30;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private alertify: AlertifyService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.authService.getPasswordReset(data.key).subscribe(result => {
        this.passwordReset = result;
        this.createResetForm();
      }, error => {
        this.alertify.error(error);
        this.router.navigate(['login']);
      });
    });
  }

  createResetForm() {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]],
      confirmPassword: ['', Validators.required]
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : { mismatch : true};
  }

  resetPassword() {
    const reset = { key: this.passwordReset.key, password: this.resetForm.get('password').value };
    this.authService.resetPassword(reset).subscribe(result => {
      this.authService.setConfirmLogin();
      this.alertify.success('Password has been reset');
      this.router.navigate(['login']);
    }, error => {
      this.alertify.error(error);
    })
  }
}
