import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Member } from '../_models/member';
import { ParentChild } from '../_models/ParentChild';
import { ParentInvitation } from '../_models/parentInvitation';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(this.baseUrl + 'members');
  }

  getMember(id: number): Observable<Member> {
    return this.http.get<Member>(this.baseUrl + 'members/' + id);
  }

  updateMember(id: number, member: Member) {
    return this.http.put(this.baseUrl + 'members/' + id, member);
  }

  setMainPhoto(memberId: number, photoId: number) {
    return this.http.post(this.baseUrl + 'members/' + memberId + '/photos/' + photoId + '/setMain', {});
  }

  deletePhoto(memberId: number, photoId: number) {
    return this.http.delete(this.baseUrl + 'members/' + memberId + '/photos/' + photoId);
  }

  createChild(knownAs: string) {
    return this.http.post<ParentChild>(this.baseUrl + 'members/parent/create/' + knownAs, {});
  }

  getChildren(memberId: number): Observable<ParentChild[]> {
    return this.http.get<ParentChild[]>(this.baseUrl + 'members/children/' + memberId);
  }

  getParentalInvitation(key: string) {
    return this.http.get<ParentInvitation>(this.baseUrl + 'members/parent/invitation/' + key);
  }

  getParentalInvitations(childId: number) {
    return this.http.get<ParentInvitation[]>(this.baseUrl + 'members/parent/invitations/' + childId);
  }

  sendParentalInvitation(childId: number, email: string) {
    return this.http.post<ParentInvitation>(this.baseUrl + 'members/parent/send/' + childId + '/' + email, {});
  }

  getParents(childId: number) {
    return this.http.get<ParentChild[]>(this.baseUrl + 'members/parent/' + childId);
  }

  deleteInvitation(childId: number, email: string) {
    return this.http.post<ParentInvitation>(this.baseUrl + 'members/parent/invitations/delete/' + childId + '/' + email, {});
  }

  deleteParent(parentId: number, childId: number) {
    return this.http.post<ParentChild>(this.baseUrl + 'members/parent/delete/' + parentId + '/' + childId, {});
  }

  acceptInvitation(invitation: ParentInvitation) {
    return this.http.post<ParentInvitation>(this.baseUrl + 'members/parent/invitation/accept', invitation);
  }

  rejectInvitation(invitation: ParentInvitation) {
    return this.http.post<ParentInvitation>(this.baseUrl + 'members/parent/invitation/reject', invitation);
  }
}
