import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { SecretSanta } from 'src/app/_models/secretSanta';

@Component({
  selector: 'app-secret-santa',
  templateUrl: './secret-santa.component.html',
  styleUrls: ['./secret-santa.component.css']
})
export class SecretSantaComponent implements OnInit {

  secretSantasHosting: SecretSanta[];
  secretSantasInvited: SecretSanta[];
  invitedStatusCodes: string[];
  hostingStatusCodes: string[];
  wishlistMember: WishlistMember;
  error: any;
  showActiveInvited = true;
  showActiveHosting = true;

  constructor(private authService: AuthService,
              private alertify: AlertifyService,
              private wishlistMemberService: WishlistMemberService) { }

  ngOnInit() {
    this.invitedStatusCodes = ['PENDING', 'ACTIVE'];
    this.hostingStatusCodes = ['PENDING', 'ACTIVE'];
    this.wishlistMemberService.getWishlistMember(this.authService.currentMember.id).subscribe(wishlistMember => {
      this.wishlistMember = wishlistMember;
      this.wishlistMemberService.getSecretSantas().subscribe(secretSantas => {
        this.secretSantasHosting = secretSantas.filter(ss => ss.wishlistMember.id === this.wishlistMember.id);
        this.secretSantasInvited = secretSantas.filter(ss => ss.wishlistMember.id !== this.wishlistMember.id);
        // this.secretSantas.forEach(secretSanta => {
        //   console.log(secretSanta.wishlistMember.member.knownAs);
        // });
      }, error => {
        this.error = error;
      });
    }, error => {
      this.error = error;
    });

  }

  deleteSecretSanta(secretSanta: SecretSanta) {
    return false;
  }

  notImplemented(secretSanta: SecretSanta) {
    this.alertify.error('Not Implemented');
    return false;
  }

  spliceLoginEmails(wishlistMember: WishlistMember) {
    return wishlistMember.member.logins.map(login => login.email);
  }

  toggleShowActiveInvited() {
    this.showActiveInvited = !this.showActiveInvited;
    if (this.showActiveInvited) {
      this.invitedStatusCodes = ['PENDING', 'ACTIVE'];
    } else {
      this.invitedStatusCodes = ['INACTIVE'];
    }
  }

  toggleShowActiveHosting() {
    this.showActiveHosting = !this.showActiveHosting;
    if (this.showActiveHosting) {
      this.hostingStatusCodes = ['PENDING', 'ACTIVE'];
    } else {
      this.hostingStatusCodes = ['INACTIVE'];
    }
  }
}
