<div *ngIf="this.parentInvitation" class="container">
  <div class="row table-heading">
    {{ this.parentInvitation.parent.knownAs }} has invited you to be a parent of {{ this.parentInvitation.child.knownAs }}
  </div>
  <!-- <div class="row">
    <div class="col-sm-12">
      <strong>Child Name</strong>
      {{ this.parentInvitation.child.knownAs }}
    </div>
  </div> -->
  <div class="row">
    <div class="col-sm-4">
      <strong>Email:</strong>
      {{this.parentInvitation.email}}
    </div>
    <!-- <div class="col-sm-4">
      <strong>Inviting Parent:</strong>
      {{ this.parentInvitation.parent.knownAs }}
    </div> -->
    <div class="col-sm-6">
      <strong>Key:</strong>
      {{ this.parentInvitation.key }}
    </div>
    <div class="col-sm-2">
      <strong>{{ this.parentInvitation.statusCode }}:</strong>
       {{ this.parentInvitation.statusDate | date }}
    </div>
  </div>
  <div class="row">
      <button class="btn btn-success my-2 my-sm-0" (click)="this.acceptInvitation()">Accept Invitation</button>
      <button class="btn btn-danger my-2 my-sm-0" (click)="this.rejectInvitation()">Reject Invitation</button>
  </div>
</div>
<div *ngIf="!this.parentInvitation" class="container">
  Unable to locate the invitation for {{ this.parentInvitation.key }}.
</div>