<block-ui>
  <form *ngIf="!this.loading" [formGroup]="parentInvitation" (ngSubmit)="sendParentalInvitation(this.parentInvitation.get('email').value)">
  <table *ngIf="this.parentChildren">
    <tr>
      <th colspan=6 class="table-heading">Parents of {{ this.parentChildren[0].child.knownAs }}</th>
    </tr>
    <tr>
      <th>Actions</th>
      <th>Parent</th>
      <th>Created</th>
    </tr>
    <tr *ngFor="let parentChild of this.parentChildren | sort: 'created' | sort: 'email'">
        <td *ngIf="parentChild.createdBy === parentChild.parentId">
            <button>Primary</button>
        </td>
        <td *ngIf="parentChild.createdBy !== parentChild.parentId">
            <button class="btn btn-danger" (click)='this.deleteParent(parentChild);'>Delete</button>
        </td>
      <td>{{ parentChild.parent.knownAs }}</td>
      <td>{{ parentChild.created | date : 'medium' }}</td>
    </tr>
    <tr>
      <td><button>Send Invitation</button></td>
      <td colspan=2>
        <input type="email" class="form-control"
        formControlName="email" placeholder="Email Address">
      </td>
    </tr>
  </table>
  </form>
  <table>
    <tr>
      <th colspan=6 class="table-heading">Completed Invitations</th>
    </tr>
    <tr>
      <th>Actions</th>
      <th>Child</th>
      <th>Key</th>
      <th>Email</th>
      <th>Status</th>
      <th>Created</th>
    </tr>
    <tr *ngFor="let invitation of this.parentalInvitations | filter: 'statusCode': ['Accepted', 'Rejected'] | sort: 'childId'">
      <td>
        <button *ngIf="invitation.statusCode === 'Accepted'" class="btn btn-danger" (click)='this.deleteInvitation(invitation);'>Delete</button>
      </td>
      <td>{{ invitation.child.knownAs }}</td>
      <td>{{ invitation.key }}</td>
      <td>{{ invitation.email }}</td>
      <td>{{ invitation.statusCode }}</td>
      <td>{{ invitation.statusDate | date:'medium' }}</td>
    </tr>
  </table>
  <table>
    <tr>
      <th colspan=6 class="table-heading">Pending Invitations</th>
    </tr>
    <tr>
      <th>Actions</th>
      <th>Child</th>
      <th>Key</th>
      <th>Email</th>
      <th>Status</th>
      <th>Created</th>
    </tr>
    <tr *ngFor="let invitation of this.parentalInvitations | exclude: 'statusCode': ['Accepted','Rejected'] | sort: 'childId'">
      <td>
        <button class="btn btn-success" (click)='this.sendParentalInvitation(invitation.email);'>Resend</button>
        <button class="btn btn-danger" (click)='this.deleteInvitation(invitation);'>Cancel</button>
      </td>
      <td>{{ invitation.child.knownAs }}</td>
      <td>{{ invitation.key }}</td>
      <td>{{ invitation.email }}</td>
      <td>{{ invitation.statusCode }}</td>
      <td>{{ invitation.statusDate | date:'medium' }}</td>
    </tr>
  </table>
</block-ui>
