import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_guards/auth.guard';
import { WishlistComponent } from './wishlist/wishlist.component';
import { WishlistDetailComponent } from './wishlist/wishlist-detail/wishlist-detail.component';
import { WishlistMemberDetailResolver } from './_resolvers/wishlist-detail.resolver';
import { WishlistMemberListResolver } from './_resolvers/wishlist-list.resolver';
import { WishlistEditComponent } from './wishlist/wishlist-edit/wishlist-edit.component';
import { WishlistMemberEditResolver } from './_resolvers/wishlist-edit.resolver';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { WarnCluechaseExit } from './_guards/warn-cluechase-exit.guard';
import { InvitationComponent } from './wishlist/invitation/invitation.component';
import { LoginComponent } from './authentication/login/login.component';
import { PasswordComponent } from './authentication/password/password.component';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { WishlistGuard } from './_guards/wishlist.guard';
import { MemberParentComponent } from './members/member-parent/member-parent.component';
import { ParentInvitationsResolver } from './_resolvers/parent-invitations.resolver';
import { ParentInvitationResolver } from './_resolvers/parent-invitation.resolver';
import { ParentInvitationComponent } from './members/parent-invitation/parent-invitation.component';
import { DragComponent } from './drag/drag.component';
import { SecretSantaDetailComponent } from './wishlist/secret-santa/secret-santa-detail/secret-santa-detail.component';
import { SecretSantaEditComponent } from './wishlist/secret-santa/secret-santa-edit/secret-santa-edit.component';
import { LaserduelComponent } from './games/laserduel/laserduel.component';
import { GamesComponent } from './games/games.component';
import { VivecraftComponent } from './games/vivecraft/vivecraft.component';
import { MyGamesComponent } from './games/clue-chase/my-games/my-games.component';
import { PlayGameComponent } from './games/clue-chase/play-game/play-game.component';
import { LocationMaintComponent } from './games/clue-chase/location-maint/location-maint.component';
import { ClueChaseResolver } from './_resolvers/clue-chase.resolver';
import { LocationResolver } from './_resolvers/location.resolver';
import { MyClueChasesComponent } from './games/clue-chase/my-clue-chases/my-clue-chases.component';

export const appRoutes: Routes = [
    { path: '', component: HomeComponent },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            // tslint:disable-next-line: max-line-length
            { path: 'member/edit/:id', component: MemberEditComponent, resolve: { member: MemberEditResolver }, canDeactivate: [PreventUnsavedChanges] },
            { path: 'member/parent/invitations/:childId', component: MemberParentComponent
                , resolve: {member: ParentInvitationsResolver} },
            { path: 'member/parent/invitation/:key', component: ParentInvitationComponent
                , resolve: {parentInvitation: ParentInvitationResolver} },
                { path: 'wishlist/invitation/:key', component: InvitationComponent },
                { path: 'clueChase/mine', component: MyClueChasesComponent, resolve: { clueChases: ClueChaseResolver} }
        ]
    },
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [WishlistGuard],
        children: [
            // tslint:disable-next-line: max-line-length
            { path: 'wishlist', component: WishlistComponent, resolve: { wishlistMembers: WishlistMemberListResolver } },
            { path: 'wishlist/edit/:id', component: WishlistEditComponent, resolve: { wishlistMember: WishlistMemberEditResolver } },
            { path: 'wishlist/:id', component: WishlistDetailComponent, resolve: { wishlistMember: WishlistMemberDetailResolver } },
            { path: 'secretSanta/:id', component: SecretSantaDetailComponent },
            { path: 'secretSantaInvitation/:key', component: SecretSantaDetailComponent },
            { path: 'secretSanta/edit/:id', component: SecretSantaEditComponent }
        ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'confirm/:key', component: ConfirmComponent },
    { path: 'reset/:key', component: PasswordComponent },
    { path: 'games/laserduel', component: LaserduelComponent },
    { path: 'games', component: GamesComponent },
    { path: 'games/vivecraft', component: VivecraftComponent },
    { path: 'drag', component: DragComponent },

    { path: 'clueChase', component: PlayGameComponent },

    { path: '**', redirectTo: '', pathMatch: 'full' }
];
