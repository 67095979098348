import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MemberService } from '../_services/member.service';
import { ParentInvitation } from '../_models/parentInvitation';
import { AuthService } from '../_services/auth.service';

@Injectable()
export class ParentInvitationResolver implements Resolve<ParentInvitation[]> {
    constructor(private memberService: MemberService,
                private authService: AuthService,
                private router: Router,
                private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ParentInvitation[]> {
        return this.memberService.getParentalInvitation(route.params.key).pipe(
            catchError(error => {
                this.alertify.error(error);
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}
