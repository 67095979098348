<block-ui>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <strong>Send an Invitation</strong>
      </div>
      <div class="col-md-12">
        Email Address:
        <input type=email [(ngModel)]="newInvitation.email">
        <input type="button" value="Send Invitation" (click)="this.sendInvitation(this.newInvitation)">
      </div>
    </div>
    <div *ngIf="this.wishlistInvitations !== null && this.wishlistInvitations.length > 0">
      <table cellpadding="3" cellspacing="3">
        <tr>
          <th colspan="4" class="column-header">
            Invitations sent to me
          </th>
        </tr>
        <tr>
          <th>From</th>
          <th>Action</th>
          <th>Status</th>
          <th>Date</th>
        </tr>
        <tr *ngFor="let invitation of wishlistInvitations | exclude: 'wishlistMemberId' : this.currentWishlistMember.id ">
          <td>{{ invitation.wishlistMember.member.knownAs }} ({{ spliceLoginEmails(invitation.wishlistMember) }})</td>
          <td>
            <button *ngIf="invitation.statusCode !== 'Accepted' && invitation.statusCode !== 'Rejected'" class="btn btn-primary" (click)="respondInvitation(invitation);">Respond</button>
            <button class="btn btn-danger" (click)="removeInvitation(invitation);">Remove</button>
          </td>
          <td>{{ invitation.statusCode }}</td>
          <td>{{ invitation.statusDate | date:'medium' }}</td>
        </tr>
      </table>
      <table cellpadding="3" cellspacing="3">
        <tr>
          <th colspan="4">
            Invitations I've sent
          </th>
        </tr>
        <tr>
          <th style="width:25%">Email</th>
          <th style="width:25%">Action</th>
          <th style="width:25%">Status</th>
          <th style="width:25%">Date</th>
        </tr>
        <tr *ngFor="let invitation of wishlistInvitations | exclude: 'email' : this.myEmails">
          <td style="width:25%">{{ invitation.email }}</td>
          <td style="width:25%">
            <button *ngIf="invitation.statusCode !== 'Rejected'" class="btn btn-danger" (click)="deleteInvitation(invitation);">Delete</button>
            <button *ngIf="invitation.statusCode !== 'Accepted' && invitation.statusCode !== 'Rejected'" class="btn btn-primary" (click)="sendInvitation(invitation);">Resend</button>
            <button *ngIf="invitation.statusCode === 'Rejected'" class="btn btn-secondary" (click)="hideInvitation(invitation);">Hide</button>  
          </td>
          <td style="width:25%">{{ invitation.statusCode }}</td>
          <td style="width:25%">{{ invitation.statusDate | date:'medium' }}</td>
        </tr>
      </table>
      <table cellpadding="3" cellspacing="3" *ngIf="this.suggestedInvitations && this.suggestedInvitations.length > 0">
        <tr>
          <th colspan="4">
            Suggested for you
          </th>
        </tr>
        <tr>
          <th style="width:25%">Wishlist Member</th>
          <th style="width:25%">Common Count</th>
          <th style="width:25%">Common List</th>
          <th style="width:25%">Action</th>
        </tr>
        <tr *ngFor="let invitation of suggestedInvitations">
          <td style="width:25%">{{ invitation.wishlistMember.member.knownAs }}</td>
          <td style="width:25%">{{ invitation.commonMembers.length }}</td>
          <td style="width:25%">{{ invitation.commonMembersList}}</td>
          <td style="width:25%">
            <button class="btn btn-primary" (click)="sendSuggestedInvitation(invitation)">Send</button>
          </td>
        </tr>
      </table>      
    </div>
  </div>
</block-ui>