import { Component, OnInit } from '@angular/core';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';

import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Month } from 'src/app/_models/month';
import { MemberService } from 'src/app/_services/member.service';
import { Gift } from 'src/app/_models/gift';

@Component({
  selector: 'app-wishlist-edit',
  templateUrl: './wishlist-edit.component.html',
  styleUrls: ['./wishlist-edit.component.css']
})
export class WishlistEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  wishlistMember: WishlistMember;
  monthObjects = new Array<Month>({id: 1, days: 31, display: 'January'},
                                {id: 2, days: 29, display: 'February'},
                                {id: 3, days: 31, display: 'March'},
                                {id: 4, days: 30, display: 'April'},
                                {id: 5, days: 31, display: 'May'},
                                {id: 6, days: 30, display: 'June'},
                                {id: 7, days: 31, display: 'July'},
                                {id: 8, days: 31, display: 'August'},
                                {id: 9, days: 30, display: 'September'},
                                {id: 10, days: 31, display: 'October'},
                                {id: 11, days: 30, display: 'November'},
                                {id: 12, days: 31, display: 'December'});
  monthOptions = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);
  dayOptions = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31);
  loading = true;

  constructor(private wishlistMemberService: WishlistMemberService,
              private memberService: MemberService,
              private authService: AuthService,
              private alertify: AlertifyService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.wishlistMember = data.wishlistMember;
      if (this.wishlistMember && this.wishlistMember.memberID !== this.authService.currentMember.id) {
        this.memberService.getChildren(this.authService.currentMember.id).subscribe(parentChildren => {
          if (parentChildren.find(pc => pc.childId === this.wishlistMember.memberID)) {
            const childGifts = new Array<Gift>();
            this.wishlistMember.myGifts.map(gift => {
              if (gift.createdById === this.wishlistMember.id) {
                childGifts.push(gift);
              }
            });
            this.wishlistMember.myGifts = childGifts;
            this.finishLoading();
          } else {
            this.alertify.error('Unauthorized');
            this.router.navigate(['home']);
          }
        }, error => {
          this.alertify.error(error);
        });
      } else {
        this.finishLoading();
      }
    });
  }

  finishLoading() {
    let birthMonth = -1;
    if (this.wishlistMember) {
      birthMonth = this.wishlistMember.birthMonth;
    }
    const monthIndex = this.monthObjects.findIndex(m => m.id === birthMonth);
    if (monthIndex > -1) {
      this.changeMonth(monthIndex);
    }
    this.loading = false;
  }

  updateWishlist(wishlistForm) {
    this.blockUI.start('Updating Wishlist...');
    this.wishlistMemberService.updateWishlistMember(this.authService.decodedToken.nameid, this.wishlistMember).subscribe(next => {
      this.alertify.success('Wishlist updated successfully');
      const lastChanged = new Date();
      this.wishlistMember.lastChanged = lastChanged;
      wishlistForm.reset(this.wishlistMember);
      this.blockUI.stop();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }

  compareOption(option1: string, option2: string): boolean {
    return option1 === option2;
  }

  changeMonth(monthIndex: number) {
    this.wishlistMember.birthMonth = this.monthObjects[monthIndex].id;
    this.dayOptions = [];
    for (let index = 0; index < this.monthObjects[monthIndex].days; index ++) {
      const day = index + 1;
      this.dayOptions.push(day);
    }
  }
  setCurrentWishlistMember(result: WishlistMember) {
    this.wishlistMember = result;
  }
}
