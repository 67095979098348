import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { MemberService } from 'src/app/_services/member.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ParentInvitation } from 'src/app/_models/parentInvitation';

@Component({
  selector: 'app-parent-invitation',
  templateUrl: './parent-invitation.component.html',
  styleUrls: ['./parent-invitation.component.css']
})
export class ParentInvitationComponent implements OnInit {

  parentInvitation: ParentInvitation;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private alertify: AlertifyService,
              private memberService: MemberService,
              private authService: AuthService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.parentInvitation = data.parentInvitation;
    });
  }
  acceptInvitation() {
    // this.wishlistMemberService.acceptInvitation(this.invitation).subscribe(result => {
    //   this.authService.setConfirmLogin();
    //   this.router.navigate(['wishlist']);
    // }, error => {
    //   this.error = error;
    // });
    this.memberService.acceptInvitation(this.parentInvitation).subscribe(result => {
      this.router.navigate(['member/edit/' + this.authService.currentMember.id]);
    }, error => {
      this.alertify.error(error);
    });
  }

  rejectInvitation() {
    this.memberService.rejectInvitation(this.parentInvitation).subscribe(result => {
      this.router.navigate(['member/edit/' + this.authService.currentMember.id]);
    }, error => {
      this.alertify.error(error);
    });
  }
}
