<block-ui>
  <div class="container">
    <table *ngIf="this.giftList?.length > 0 " cellpadding="3" cellspacing="3">
      <ng-container *ngFor="let gift of this.giftList">
        <tr>
          <td colspan="4">
            <button *ngIf="gift.createdById == this.currentWishlistMember.id" 
              class="btn btn-primary" 
              style="width:45%;" 
              (click)="editGift(gift)">Edit this gift</button>
            <button *ngIf="gift.fromId != null && gift.from.id == this.currentWishlistMember.id" 
              class="btn btn-danger" 
              style="width:45%;" 
              (click)="promptTakeback(gift)">Changed my mind</button>
          </td>
        </tr>
        <tr *ngIf="gift.error && gift.error.length > 0">
          <td colspan="4" style="background-color: #ff0000; color: #ffffff;">
            {{ gift.error }}
          </td>
        </tr>
        <tr>
          <th>To</th>
          <th>Description</th>
          <th>Is it likely?</th>
          <th>When?</th>
        </tr>
        <tr>
          <td>
            {{ gift.to?.member.knownAs }}
          </td>
          <td>
            {{gift.description}}
          </td>
          <td>
            {{gift.expectation}}
          </td>
          <td>
            {{gift.event}}
          </td>
        </tr>
        <tr>
          <td colspan="4" style="align-content: left  ">
            <ul>
              <li *ngFor="let link of gift.giftLinks">
                <a href="{{link.url}}" target="_blank" rel="noopener noreferrer">
                  {{link.name}}
                </a>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <hr class="hline-bottom">
          </td>
        </tr>
        </ng-container>
    </table>
  </div>
</block-ui>
