<block-ui>
  <div *ngIf="this.loading === false && this.wishlistMember" class="container-fluid">
    <form #wishlistForm="ngForm" id="wishlistForm" (ngSubmit)="updateWishlist(wishlistForm)">
      <div class="row">
        <div class="col-sm-12">
          <h2>{{ this.wishlistMember.member.knownAs }}'s Wishlist</h2>

        </div>
      </div>
      <div *ngIf="wishlistForm.dirty" class="alert alert-info row">
        <strong>Information:</strong>You have made changes.  Any unsaved changes will be lost!
      </div>
      <div class="row">
        <div class="col-sm-8">
          <div>
            <strong>Birth Month/Day</strong>
            <select *ngIf="this.wishlistMember.birthMonth != null" name="birthMonth" [compareWith]="compareOption" 
              [ngModel]="this.wishlistMember.birthMonth" (change)="changeMonth($event.target.value[0])">
              <option *ngFor="let option of monthOptions; let i = index;" [ngValue]="option">
                  {{ monthObjects[i].display }}
              </option>
            </select>
            /
            <select name="birthDom" [compareWith]="compareOption" [(ngModel)]="this.wishlistMember.birthDom">
              <option *ngFor="let option of dayOptions" [ngValue]="option">
                  {{option}}
              </option>
            </select>
          </div>
          <div>
            <strong>Shipping Address</strong>
            <input type="text" name="shippingAddress" class="form-control" name="shippingAddress"
            [(ngModel)]="this.wishlistMember.shippingAddress">
          </div>
          <div>
            <strong>Favorite Color</strong>
            <input type="text" name="favoriteColor" class="form-control" name="favoriteColor"
            [(ngModel)]="this.wishlistMember.favoriteColor">
          </div>
          <div>
            <strong>Pant Size</strong>
            <input type="text" name="pantSize" class="form-control" name="pantSize"
            [(ngModel)]="wishlistMember.pantSize">
          </div>
          <div>
            <strong>Shirt Size</strong>
            <input type="text" name="shirtSize" class="form-control" name="shirtSize"
            [(ngModel)]="wishlistMember.shirtSize">
          </div>
          <div>
            <strong>Shoe Size</strong>
            <input type="text" name="shoeSize" class="form-control" name="shoeSize"
            [(ngModel)]="wishlistMember.shoeSize">
          </div>
          <div>
            <strong>Clothing Misc</strong>
            <input type="text" name="clothingMisc" class="form-control" name="clothingMisc"
            [(ngModel)]="wishlistMember.clothingMisc">
          </div>
          <div>
            <strong>Last Changed</strong>
            <p>{{wishlistMember.lastChanged | date}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <button type="submit" style="width:100%;margin-bottom: 15px" class="btn btn-success" form="wishlistForm" [disabled]="(!wishlistForm.form.valid || !wishlistForm.dirty) && !giftChanged">Submit</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <div class="tab-panel">
            <tabset class="member-tabset">
              <tab heading="Gifts">
                <app-gift-list [wishlistMember]="this.wishlistMember" [currentWishlistMember]="this.wishlistMember"></app-gift-list>
              </tab>
              <tab heading="Interests">
                <textarea class="form-control" name="interests" rows="14"
                  [(ngModel)]="wishlistMember.interests"></textarea>
              </tab>
              <tab heading="Hobbies">
                <textarea name="hobbies" rows="14" class="form-control" [(ngModel)]="wishlistMember.hobbies"></textarea>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="this.wishlistMember == null" class="row">
    <app-wishlist-create [wishlistMember]="this.wishlistMember" (createdWishlistMember)="setCurrentWishlistMember($event)"></app-wishlist-create>
  </div>
</block-ui>