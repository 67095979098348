import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClueChaseLocation } from 'src/app/_models/clueChaseLocation';
import { FilterPipe } from 'src/app/_pipes/filterPipe';
@Component({
  selector: 'app-map-display',
  templateUrl: './map-display.component.html',
  styleUrls: ['./map-display.component.scss']
})
export class MapDisplayComponent implements OnInit {
  @Input()
  mapPosition: any;
  @Input()
  clueChaseLocations: ClueChaseLocation[];
  @Input()
  circleRadius: any;
  @Input()
  zoom: string;
  @Input()
  editMode: boolean;
  @Input()
  showTrackType: boolean = false;

  @Output() locationMethodChanged: EventEmitter<string> = new EventEmitter<string>();

  filterPipe: FilterPipe = new FilterPipe();
  mapType: string = 'roadmap'; // roadmap, satelite
  trackType: string = 'watch'; // poll, watch
  //showMarkers:any[] = [{key:'found', value:true}, {key:'editMode'}];
  constructor() { }

  ngOnInit() {
  }

  iterateLocations() {
    if (this.editMode) {
      return this.clueChaseLocations;
    }
    
    //return this.filterPipe.transform(this.clueChaseLocations, 'location.found', true);;
    return this.clueChaseLocations.filter(clueChaseLocation => {
      return clueChaseLocation.location.found;
    });
  }

  toggleMapType() {
    if (this.mapType === 'roadmap') {
      this.mapType = 'satellite';
    } else {
      this.mapType = 'roadmap'
    }
  }

  toggleTrackType() {
    if (this.trackType === 'watch') {
      this.trackType = 'poll';
    } else {
      this.trackType = 'watch';
    }
    this.locationMethodChanged.emit(this.trackType);
  }
}
