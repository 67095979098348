import { Component, OnInit } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  error = null;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private alertify: AlertifyService,
              private authService: AuthService) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.authService.confirmLogin(data.key).subscribe(() => {
        this.authService.setConfirmLogin();
        this.alertify.success('Confirmation Successful!');
        this.router.navigate(['home']);
      }, error => {
        this.error = error;
      });
    });
  }
}
