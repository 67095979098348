import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Gift } from 'src/app/_models/gift';
import { Link } from 'src/app/_models/link';
import { GiftPopupComponent } from '../gift-popup/gift-popup.component';
import { AuthService } from 'src/app/_services/auth.service';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MemberService } from 'src/app/_services/member.service';
import { ParentChild } from 'src/app/_models/ParentChild';
import { Member } from 'src/app/_models/member';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-gift-list',
  templateUrl: './gift-list.component.html',
  styleUrls: ['./gift-list.component.css']
})
export class GiftListComponent implements OnInit {
  @Input() wishlistMember: WishlistMember;
  @Input() currentWishlistMember: WishlistMember;
  @BlockUI() blockUI: NgBlockUI;

  currentParentChildren: ParentChild[];
  filteredGifts: Gift[];
  searchValue: string;

  constructor(private authService: AuthService,
              private modalService: NgbModal,
              private wishlistMemberService: WishlistMemberService,
              private memberService: MemberService,
              private alertify: AlertifyService) { }

  ngOnInit() {

    this.memberService.getChildren(this.authService.currentMember.id).subscribe(parentChildren => {
      this.currentParentChildren = parentChildren;
    });
    if (this.wishlistMember) {
      // this.filteredGifts = this.wishlistMember.myGifts;
      this.filterGifts();
    }
  }

  newGift() {
    const gift = {toId: this.wishlistMember.id, fromId: null, expectation: '', anonymous: false};
    if (this.currentWishlistMember.id !== this.wishlistMember.id
        && !this.currentParentChildren.find(pc => pc.childId === this.wishlistMember.memberID)) {
      gift.expectation = 'Unexpected';
      gift.fromId = this.currentWishlistMember.id;
      gift.anonymous = true;
    }
    const newGift = JSON.parse(JSON.stringify(gift));
    this.editGift(newGift);
    return false;
  }

  editGift(gift: Gift) {
    const modalRef = this.modalService.open(GiftPopupComponent);
    modalRef.componentInstance.gift = gift;
    modalRef.result.then((updatedGift: Gift) => {
      this.saveGift(updatedGift);
      this.filterGifts();
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }

  saveGift(gift: Gift) {
    console.log(gift);
    this.blockUI.start('Saving gift...');
    if (gift.id == null) {
      this.wishlistMemberService.createGift(this.currentWishlistMember.member.id, gift).subscribe(giftResult => {
        this.wishlistMember.myGifts.push(giftResult);
        this.filterGifts();
        this.blockUI.stop();
      }, error => {
        this.alertify.error(error);
        this.blockUI.stop();
      });
    } else {
      this.wishlistMemberService.updateGift(gift).subscribe(giftResult => {
        const giftIndex = this.wishlistMember.myGifts.findIndex(g => g.id === giftResult.id);
        this.wishlistMember.myGifts[giftIndex] = giftResult;
        this.filterGifts();
        this.blockUI.stop();
      }, error => {
        this.alertify.error(error);
        this.blockUI.stop();
      });
    }
  }

  deleteGift(gift: Gift) {
    this.alertify.confirm('Are you sure you want to delete ' + gift.description + '?', () => {
      this.blockUI.start('Deleting gift...');
      this.wishlistMemberService.deleteGift(gift).subscribe(giftId => {
        const giftIndex = this.wishlistMember.myGifts.findIndex(g => g.id === giftId);
        this.wishlistMember.myGifts.splice(giftIndex, 1);
        this.filterGifts();
        this.blockUI.stop();
      }, error => {
        this.alertify.error(error);
        this.blockUI.stop();
      });
    });

    return false;
  }

  promptGive(gift: Gift) {

    this.alertify.confirmCancel('Do you want to stay anonymous?', (result: boolean) => {
      gift.anonymous = result;
      this.give(gift);
    }, 'Anonymity');
  }

  give(gift: Gift) {
    this.blockUI.start('Giving gift...');
    this.wishlistMemberService.giveGift(gift).subscribe((result: WishlistMember) => {
      this.wishlistMember = result;
      this.filterGifts();
      this.blockUI.stop();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }

  applyAnonymous(gift: Gift, anonymity: boolean) {
    gift.anonymous = anonymity;
    this.blockUI.start('Changing anonymity...');
    this.wishlistMemberService.applyAnonymous(gift).subscribe((result: WishlistMember) => {
      this.wishlistMember = result;
      this.filterGifts();
      this.blockUI.stop();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }

  promptTakeback(gift: Gift) {
    if (gift.createdById !== gift.fromId) {
      this.takeback(gift);
      return false;
    }
    this.alertify.confirm('Are you sure you wish to Remove this gift that you added?', () => {
      this.takeback(gift);
    });
    return false;
  }

  takeback(gift: Gift) {
    this.blockUI.start('Taking back gift...');
    this.wishlistMemberService.takebackGift(gift).subscribe((result: WishlistMember) => {
      this.wishlistMember = result;
      this.filterGifts();
      this.blockUI.stop();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }

  applyGiftFilter(event: any) {
    this.searchValue = event.target.value;
    this.filterGifts();
  }

  filterGifts() {
    if (this.searchValue && this.searchValue.length > 0) {
      // tslint:disable-next-line: max-line-length
      if (this.wishlistMember && this.wishlistMember.myGifts != null) {
        this.filteredGifts = (this.wishlistMember.myGifts.filter((gift) => 
          JSON.stringify(gift).toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1)
        );
      }

    } else if (this.wishlistMember) {
      this.filteredGifts = this.wishlistMember.myGifts;
    }
    this.filteredGifts = this.filteredGifts.sort((a: Gift, b: Gift) => (a.id > b.id) ? -1 : 1);
  }
}
