<div class="modal-header">
  <h4 class="modal-title">{{ this.modalTitle }}</h4>
  <button type="button" class="fa fa-close" aria-label="Close"
    (click)="cancel()">
 </button>  
</div>
<div class="modal-body">
  <table>
    <tr>
        <th>Description</th>
        <th>Is it likely</th>
        <th>When?</th>
      </tr>
      <tr>
        <td>
          <input type="text" [(ngModel)]="this.editGift.description">
        </td>
        <td>
          <select *ngIf="!this.expectation" [compareWith]="compareOption" [(ngModel)]="this.editGift.expectation">
            <option *ngFor="let option of expectationOptions" [ngValue]="option">
                {{option}}
            </option>
          </select>
          <div *ngIf="this.expectation">{{this.expectation}}</div>
        </td>
        <td>
          <input type="text" [(ngModel)]="this.editGift.event">
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <ul>
            <li *ngFor="let link of gift.giftLinks">
              <a href="{{link.url}}" target="_blank" rel="noopener noreferrer">
                <i class="fa info-circle"></i>{{link.name}}
              </a>
            </li>
          </ul>
        </td>
      </tr>
  </table>
  <table>
    <tr>
      <th>Action</th>
      <th>Link Description</th>
      <th>Link (URL) - must exist!</th>
    </tr>
    <tr *ngFor="let link of this.editGift.giftLinks">
      <td>
        <button class="btn-danger" (click)="deleteLink(link)">Delete</button>
      </td>
      <td>
          <input type="text" [(ngModel)]="link.name">
      </td>
      <td>
        <input type="url" [(ngModel)]="link.url">
      </td>
    </tr>
    <tr>
      <td>New&nbsp;</td>
      <td>
          <input type="text" [ngModel]="newLink?.name" (ngModelChange)="newLink.name=$event">
      </td>
      <td>
        <input type="url" [ngModel]="newLink?.url" (ngModelChange)="newLink.url=$event">
      </td>
    </tr>
  </table>
</div>

<div class="modal-footer">
    <button type="button" (click)="cancel();">Cancel</button>
    <button type="button" (click)="saveAndClose(this.editGift);">Save</button>
</div>