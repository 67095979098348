import { Component, OnInit } from '@angular/core';
import { ParentChild } from 'src/app/_models/ParentChild';
import { MemberService } from 'src/app/_services/member.service';
import { ParentInvitation } from 'src/app/_models/parentInvitation';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-member-parent',
  templateUrl: './member-parent.component.html',
  styleUrls: ['./member-parent.component.css']
})
export class MemberParentComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  parentInvitation: FormGroup;
  currentMemberId: number;
  childId: number;
  parentChildren: ParentChild[];
  parentalInvitations: ParentInvitation[];
  loading = true;

  constructor(private route: ActivatedRoute,
              private memberService: MemberService,
              private authService: AuthService,
              private alertify: AlertifyService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.currentMemberId = this.authService.currentMember.id;
    this.childId = this.route.snapshot.params.childId;
    this.initializeInvitationForm();
    this.route.data.subscribe(data => {
      this.parentalInvitations = data.member;
      this.memberService.getParents(this.childId).subscribe(result => {
        this.parentChildren = result;
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.loading = false;
      });
    });
  }

  initializeInvitationForm() {
    this.parentInvitation = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  sendParentalInvitation(email: string) {
    this.blockUI.start('Sending invitation to ' + email);
    this.memberService.sendParentalInvitation(this.childId, email).subscribe(result => {
      const parentInvitationIndex = this.parentalInvitations.findIndex(pi => pi.id === result.id);
      if (parentInvitationIndex > -1) {
        this.parentalInvitations[parentInvitationIndex] = result;
        this.alertify.success('Invitation successfully resent');
      } else {
        this.parentalInvitations.push(result);
        this.alertify.success('Invitation successfully sent');
      }
    }, error => {
      this.alertify.error(error);
    }).add(() => {
      this.blockUI.stop();
    });
    return false;
  }

  deleteInvitation(invitation: ParentInvitation) {
    this.alertify.confirm('Are you sure you want to remove ' + invitation.email + '\'s invitation'
      + ' as a parent for ' + invitation.child.knownAs + '?', () => {
        this.memberService.deleteInvitation(invitation.childId, invitation.email).subscribe(result => {
          const parentInvitationIndex = this.parentalInvitations.findIndex(pc => pc.id === result.id);
          this.parentalInvitations.splice(parentInvitationIndex, 1);
        }, error => {
          this.alertify.error(error);
        });
    });
    return false;
  }

  deleteParent(parentChild: ParentChild) {
    this.alertify.confirm('Are you sure you want to remove ' + parentChild.parent.knownAs
      + ' as a parent for ' + parentChild.child.knownAs + '?', () => {
        this.memberService.deleteParent(parentChild.parentId, this.childId).subscribe(result => {
          const parentChildIndex = this.parentChildren.findIndex(pc => pc.id === result.id);
          this.parentChildren.splice(parentChildIndex, 1);
        }, error => {
          this.alertify.error(error);
        });
    });
    return false;
  }
}
