import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { SecretSanta } from 'src/app/_models/secretSanta';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { ActivatedRoute, Router } from '@angular/router';
import { SecretSantaInvitation } from 'src/app/_models/secretSantaInvitation';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-secret-santa-edit',
  templateUrl: './secret-santa-edit.component.html',
  styleUrls: ['./secret-santa-edit.component.css']
})
export class SecretSantaEditComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  secretSanta: SecretSanta;
  secretSantaInvitation: SecretSantaInvitation;
  newSecretSantaInvitations = '';
  wishlistMember: WishlistMember;
  email: string;
  error: any;
  eventDateHasPassed: any;

  constructor(private authService: AuthService,
              private alertify: AlertifyService,
              private wishlistMemberService: WishlistMemberService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    let secretSantaId = 0;
    this.activatedRoute.params.subscribe(params => {
      secretSantaId = params.id;
    });
    this.wishlistMemberService.getWishlistMember(this.authService.currentMember.id).subscribe(wishlistMember => {
      this.wishlistMember = wishlistMember;
      this.email = this.wishlistMember.member.logins.find(login => login.source === 'wyssweb').email;
      if (!(secretSantaId > 0)) {
        const currentDate = new Date();
        this.secretSanta = {
          id: 0,
          event: '',
          description: '',
          eventDate: currentDate.toDateString(),
          statusCode: 'NEW',
          statusDate: currentDate,
          wishlistMember: this.wishlistMember,
          secretSantaInvitations: []
        };
        return false;
      }
      this.blockUI.start('Getting Secret Santa details...');
      this.wishlistMemberService.getSecretSanta(secretSantaId).subscribe(secretSanta => {
        if (this.wishlistMember.id !== secretSanta.wishlistMember.id) {
          this.router.navigate(['secretSanta/' + secretSanta.id]);
        }
        this.secretSantaInvitation = secretSanta.secretSantaInvitations.find(ssi => ssi.wishlistMemberId === this.wishlistMember.id);
        this.secretSanta = secretSanta;
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
    }, error => {
      this.alertify.error(error);
    });
  }

  drawNamesFromAHat() {
    this.alertify.confirm('Once names are drawn from the hat, this Secret Santa will become active, and you won\'t be able to make further changes', () => {
      this.blockUI.start('Drawing names from a hat...');
      this.wishlistMemberService.drawNamesFromAHat(this.secretSanta.id).subscribe(secretSanta => {
        this.secretSantaInvitation = secretSanta.secretSantaInvitations.find(ssi => ssi.wishlistMemberId === this.wishlistMember.id);
        this.secretSanta = secretSanta;
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
    });
    return false;

  }

  respondSecretSantaInvitation(secretSantaInvitation: SecretSantaInvitation, response: boolean) {
    this.wishlistMemberService.respondSecretSantaInvitation(this.secretSanta, response).subscribe(data => {
      secretSantaInvitation.statusCode = data.statusCode;
      secretSantaInvitation.statusDate = data.statusDate;
      secretSantaInvitation.wishlistMemberId = data.wishlistMember.id;
      secretSantaInvitation.wishlistMember = data.wishlistMember;
    }, error => {
      this.alertify.error(error);
    }).add(() => {
      this.blockUI.stop();
    });
  }

  navigateWishlistMember(memberId: number) {

    this.blockUI.start('Getting Secret Santa details...');
    this.wishlistMemberService.getSecretSanta(this.secretSanta.id).subscribe(secretSanta => {
      const today = new Date();
      const eventDate = new Date(secretSanta.eventDate);
      if (today > eventDate) {
        this.alertify.error('This Secret Santa has expired.');
        return false;
      }

      if (this.secretSanta.statusCode !== 'ACTIVE') {
        this.alertify.error('This Secret Santa is inactive.');
        return false;
      }
      this.router.navigate(['/wishlist/' + memberId]);
    }, error => {
      this.error = error;
    }).add(() => {
      this.blockUI.stop();
    });
    return false;
  }

  updateSecretSanta(secretSantaForm) {
    const secretSantaId = this.secretSanta.id;
    this.blockUI.start('Updating Wishlist...');
    this.wishlistMemberService.updateSecretSanta(this.secretSanta).subscribe(next => {
      this.alertify.success('Secret Santa updated successfully');
      this.error = null;
      if (secretSantaId === 0) {
        this.router.navigate(['secretSanta/edit/' + next.id]);
      }
      this.secretSanta = next;
      // Resetting the form with the date in the 'yyyy-MM-ddThh:nn:ss' format doesn't work...
      // so stripping off the 'Thh:nn:ss'
      this.secretSanta.eventDate = this.secretSanta.eventDate.split('T')[0];
      secretSantaForm.reset(this.secretSanta);
      this.secretSanta.eventDate = next.eventDate;
    }, error => {
      this.alertify.error(error);
    }).add(() => {
      this.blockUI.stop();
    });
  }

  addInvitations() {
    this.blockUI.start('Sending invitations...');
    this.wishlistMemberService.sendSecretSantaInvitations(this.secretSanta.id
        , this.newSecretSantaInvitations.split(';')).subscribe(result => {
      this.secretSanta.secretSantaInvitations = this.secretSanta.secretSantaInvitations.concat(result);
      this.newSecretSantaInvitations = '';
    }, error => {
      this.alertify.error(error);
    }).add(() => {
      this.blockUI.stop();
    });
    return false;
  }

  updateDate(eventDate) {
    this.secretSanta.eventDate = eventDate;
    const currentDate = new Date();
    const checkEventDate = new Date(this.secretSanta.eventDate + 'T23:59:59');

    if (checkEventDate < currentDate) {
      // tslint:disable-next-line: max-line-length
      this.eventDateHasPassed = true;
    } else {
      this.eventDateHasPassed = false;
    }
  }

  deleteSecretSanta() {
    this.alertify.confirm('Are you sure you want to delete this Secret Santa? The participants will not be notified... you will need to tell them yourself.', () => {
      this.blockUI.start('Deleting Secret Santa...');
      this.wishlistMemberService.deleteSecretSanta(this.secretSanta.id).subscribe(() => {
        this.router.navigate(['wishlist']);
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
    });
    return false;
  }

  deleteSecretSantaInvitation(secretSantaInvitation: SecretSantaInvitation) {

    this.alertify.confirm('Are you sure you want to delete this Secret Santa invitation?', () => {
      this.blockUI.start('Deleting Secret Santa Invitation...');
      this.wishlistMemberService.deleteSecretSantaInvitation(this.secretSanta.id
        , secretSantaInvitation.id).subscribe(() => {
        const index: number = this.secretSanta.secretSantaInvitations.indexOf(secretSantaInvitation);
        if (index !== -1) {
            this.secretSanta.secretSantaInvitations.splice(index, 1);
        }
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
    });
    return false;
  }

  resendSecretSantaInvitation(secretSantaInvitation: SecretSantaInvitation) {
    const today = new Date();
    const nextSendDate = new Date((secretSantaInvitation.statusDate + '').split('.')[0]);
    nextSendDate.setDate(nextSendDate.getDate() + 1);

    if (today < nextSendDate) {
      this.alertify.warning('You must wait until ' + nextSendDate + ' to resend this invitation');
      return false;
    }

    this.alertify.confirm('Are you sure you want to resend this Secret Santa invitation?', () => {
      this.blockUI.start('Resending Secret Santa Invitation...');
      this.wishlistMemberService.resendSecretSantaInvitation(this.secretSanta.id
        , secretSantaInvitation.id).subscribe(invitation => {
          const index: number = this.secretSanta.secretSantaInvitations.indexOf(secretSantaInvitation);
          if (index !== -1) {
              this.secretSanta.secretSantaInvitations[index] = invitation;
          }
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
      });
    });
    return false;
  }

  notImplemented(secretSanta: SecretSanta) {
    this.alertify.error('Not Implemented');
    return false;
  }
}
