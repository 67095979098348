import { Component, OnInit, Input } from '@angular/core';
import { Gift } from 'src/app/_models/gift';
import { AuthService } from 'src/app/_services/auth.service';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GiftPopupComponent } from '../gift-popup/gift-popup.component';

@Component({
  selector: 'app-gift-list-from',
  templateUrl: './gift-list-from.component.html',
  styleUrls: ['./gift-list-from.component.css']
})
export class GiftListFromComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  currentWishlistMember: WishlistMember;
  giftList: Gift[];
  loading = true;

  constructor(private authService: AuthService,
              private wishlistMemberService: WishlistMemberService,
              private modalService: NgbModal,
              private alertify: AlertifyService) { }

  ngOnInit() {
    this.blockUI.start('Loading gift list');

    this.wishlistMemberService.getWishlistMember(this.authService.currentMember.id).subscribe(wishlistMember => {
      this.currentWishlistMember = wishlistMember;
      this.wishlistMemberService.getGiftsFrom(wishlistMember.id).subscribe(gifts => {
        this.giftList = gifts;
        this.loading = false;
      }, error => {
        this.alertify.error(error);
      }).add(() => {
        this.blockUI.stop();
        this.loading = false;
      });
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
      this.loading = false;
    });
  }

  editGift(gift: Gift) {
    const modalRef = this.modalService.open(GiftPopupComponent);
    modalRef.componentInstance.gift = gift;
    modalRef.result.then((updatedGift: Gift) => {
      this.saveGift(updatedGift);
    }).catch((error) => {
      if (error != null) {
        this.alertify.error(error);
      }
    });
  }

  saveGift(gift: Gift) {
    this.blockUI.start('Saving gift...');
    this.wishlistMemberService.updateGift(gift).subscribe(giftResult => {
      const giftIndex = this.giftList.findIndex(g => g.id === giftResult.id);
      this.giftList[giftIndex] = giftResult;
      this.blockUI.stop();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }

  deleteGift(gift: Gift) {
    this.alertify.confirm('Are you sure you want to delete ' + gift.description + '?', () => {
      this.blockUI.start('Deleting gift...');
      this.wishlistMemberService.deleteGift(gift).subscribe(giftId => {
        const giftIndex = this.giftList.findIndex(g => g.id === giftId);
        this.giftList.splice(giftIndex, 1);
        this.blockUI.stop();
      }, error => {
        this.alertify.error(error);
        this.blockUI.stop();
      });
    });

    return false;
  }

  promptTakeback(gift: Gift) {
    if (gift.createdById !== gift.fromId) {
      this.takeback(gift);
      return false;
    }
    this.alertify.confirm('Are you sure you wish to Remove this gift that you added?', () => {
      this.takeback(gift);
    });
    return false;
  }

  takeback(gift: Gift) {
    this.blockUI.start('Taking back gift...');
    this.wishlistMemberService.takebackGift(gift).subscribe((result: WishlistMember) => {
      const giftIndex = this.giftList.findIndex(g => g.id === gift.id);
      this.giftList.splice(giftIndex, 1);
      this.blockUI.stop();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }
}
