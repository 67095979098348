<div class="container mt-5">
  <a href="{{this.assetsFolder}}/LaserDuel0016.mp4">Short Demo video</a>
  <br/>
  <p [align]="'center'"><a target="_blank" href="https://gitlab.com/dekaysoft/laserduel/issues">Submit LaserDuel issues/bugs/feature requests!</a> * Requires creating a login account :)</p>
  <ol>
    <li>
      For Windows, download and run the "LaserDuel for Windows"... you'll need to click "More Info", then "Run Anyway".  I do plan to sign the code eventually!!<br/>
      <br/>
      If you've already installed a previous version, I'd recommend uninstalling it before installing a new one.  It won't confuse your computer, but it confuses me.  Eventually,
      I'll modify the installer to remove previous versions automatically.
    </li>
    <li>
      For Linux/Mac... I hope you know what you're doing, 'cause I don't have either system, so I don't know how to document the process!  It's been tested in both, however...
    </li>
    
  </ol>
  <table [border]="1" cellpadding="5px" cellspacing="5px">
    <tr>
      <th colspan="2">The most latest version is at the top</th>
    </tr>
    <tr>
      <th>
        File Download (windows)
      </th>
      <th>
        Summary of changes
      </th>
    </tr>
    <tr>
      <td>
        <a href="{{this.assetsFolder}}\LaserDuelSetup0025_x86.zip">LaserDuel0025 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0025Mac.app.zip">LaserDuel0025 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0025Linux.zip">LaserDuel0025 for Linux</a><br/><br/>
      </td>
      <td>
        Replaced the floating ball Sentry with a 4 FlyingDrones from the FlyingDroneToolkit.<br/>
        Each Sentry starts high above the arena, and floats down to one of the 4 outer corners, then proceeds to patrol the outside of the arena<br/>
        As the Sentry patrols, the "head" of the sentry turns 360 degrees, scanning for a player.  When a player is detected (in a large cone from the "head"), the Sentry attempts to use line of sight to target the player.<br/>
        When a Sentry obtains line of sight of a player, it changes to pursue mode, and fires a Laser Bolt at the player's head every 3 seconds.<br/>
        If the player leaves the detection cone, the Sentry reverts to patrol mode, and resumes it's patrol around the outer corridor.<br/>
        If the player hits a Sentry three times with a Laser Bolt (sorry, no Laser Sword damage just yet), the Sentry "dies" - which means it returns to it's original position and resumes patrol duty.
        Player damage (at this time) simply cycles the color of the player between white and red, then back to white.
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0024_x86.zip">LaserDuel0024 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0024Mac.zip">LaserDuel0024 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0024Linux.zip">LaserDuel0024 for Linux</a><br/><br/> -->
      </td>
      <td>
        Replaced the sentry's avatar with a floating ball, instead of using the PC Player avatar.<br/>
        Tweaked the "PlayerHealth" script to retain the current health, instead of simply changing a default.<br/>
        Set the sentry to cycle color back to white instead of destroying it.  Will wait to destroy the sentry until after
        they get respawned automatically.
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0023_x86.zip">LaserDuel0023 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0023Mac.zip">LaserDuel0023 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0023Linux.zip">LaserDuel0023 for Linux</a><br/><br/> -->
      </td>
      <td>
        More tweaks to the LaserBolt to prevent running into your own shot.<br/>
        LaserBolt owner was resetting the position from the network sync, causing stuttering in the LaserBolt flight.<br/>
        Fixed PlayerHealth so that everyone sees the health of each player in the form of a color change.<br/>
        Sentry is now deleted when the health goes to zero, rather than being deactivated (was only being deactivated on the server)<br/>
        PCPlayers are spawned in a random corner, but VRPlayers are being spawned at (0,0,0).  Will work on this <a href="https://gitlab.com/dekaysoft/laserduel/issues/10" target="_blank">later</a>.
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0022_x86.zip">LaserDuel0022 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0022Mac.zip">LaserDuel0022 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0022Linux.zip">LaserDuel0022 for Linux</a><br/><br/> -->
      </td>
      <td>
        Laser Bolt movement is now handled by each client, with corrections coming from the controlling player.<br/>
        Cleaned up the Sentry object to handle destruction better (instead of just the head).
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0021_x86.zip">LaserDuel0021 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0021Mac.zip">LaserDuel0021 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0021Linux.zip">LaserDuel0021 for Linux</a><br/><br/> -->
      </td>
      <td>
        Laser Bolts now change direction to collide with the laser sword when the laser sword is "almost" blocking it.<br/>
        Additionally, the bolt will be deflected off of the laser sword towards the object in the middle of the user's view, allowing the user to "aim" the deflection.
        Laser Bolts continue to deflect off of non-laser swords according to the angle at which they struck the object.
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0020_x86.zip">LaserDuel0020 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0020Mac.zip">LaserDuel0020 for Mac</a><br/><br/> -->
      </td>
      <td>
        Laser Bolts now bounce off of Laser Swords, Pyramids, and one transparent wall.  Otherwise, they explode silently.<br/>
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0019_x86.zip">LaserDuel0019 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0019Mac.zip">LaserDuel0019 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuelSetup0019_Linux.zip">LaserDuel0019 for Linux</a><br/><br/> -->
      </td>
      <td>
        Replaced the "Enable VR" button with a checkbox, and reset it to off when returning to the main game (either by pressing "Home" in the game, or the server shutting down)
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0018_x86.zip">LaserDuel0018 for Windows</a><br/><br/> -->
      </td>
      <td>
        Implemented the "PlayerJoined" and "PlayerDisconnected" correctly (I hope).			
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0017_x86.zip">LaserDuel0017 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel0017Mac.app">LaserDuel0017 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuelSetup0017_Linux.zip">LaserDuel0017 for Linux</a><br/><br/> -->
      </td>
      <td>
        Ripped out the "Unity Networking" (UNet), and replaced with Forge Networking.
        Now the PC and VR avatars can see each other move around (including the hands/fingers on the VR avatar!).  In addition, the position/rotation of the Laser Gun 
        and Laser Sword is synched across the network!<br/>
        <br/>
        Turning on the Laser Sword or shooting the Laser Gun is also synched across the network... but there are some issues.<br/>
        <ol>
          <li>Joining a game in progress will result in all of the laser bolts fired before you joined being fired all at once</li>
          <li>When a player leaves the game, their avatar is left behind... rejoining will create a new avatar.</li>
        </ol>
        
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0.0.16_x86.zip">LaserDuel0016 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel_0_0_16_Mac.app">LaserDuel0016 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel_0_0_16_Linux.zip">LaserDuel0016 for Linux</a><br/><br/> -->
      </td>
      <td>
        Each client now has a holstered laser gun and laser sword.  Pressing the grip button places the weapon in that hand... the sword's blade is extended
        when you press the trigger, and retracted when you release the trigger.
  
        Unfortunately, the weapons are not synced across the network... yet!
        
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0.0.15_x86.zip">LaserDuel0015 for Windows</a> -->
      </td>
      <td>
        Fixed a bug where the VRPlayer's left hand wasn't animating the fingers.  Also added a laser gun to the robot model for feedback (on asthetics)
      </td>
    </tr>
    <tr>
      <td>
        <!-- <a href="{{this.assetsFolder}}\LaserDuelSetup0.0.14_x86.zip">LaserDuel0014 for Windows</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuelMac_0014.zip">LaserDuel0014 for Mac</a><br/><br/>
        <a href="{{this.assetsFolder}}\LaserDuel_0014_Linux.zip">LaserDuel0014 for Linux</a><br/><br/> -->
      </td>
      <td>
      
        This version provides a non-VR avatar (a capsule with sunglasses and a barrel-gun) for non VR players.
  
      
        This version has been tested with 2 VR systems over the internet.  
        Currently, only the head/hands are networked (including the fingers), but 
        nothing else.
        
        <ol>
          <li>
            New blue/green galaxy skybox gives it a much groovier look!<
          </li>
          <li>
            2 Laser Swords have been added... one in the hand of a robot model I want to 
            eventually use for an avatar (can't take the sword out of the robot's hand, sorry!).
            The 2nd sword will eventually be able to be picked up and turned on/off.  It's just there
            as a teaser for now :D
          </li>
        </ol>
      </td>
    </tr>
  </table>
  