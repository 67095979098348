import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Gift } from 'src/app/_models/gift';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Link } from 'src/app/_models/link';

@Component({
  selector: 'app-gift-popup',
  templateUrl: './gift-popup.component.html',
  styleUrls: ['./gift-popup.component.css']
})
export class GiftPopupComponent implements OnInit {

  @Input() public gift: Gift;
  editGift: Gift;
  expectation: string;
  newLink: Link = { id: 0, giftId: 0, description: '', name: '', url: '',
                    isActive: true, created: new Date(), updated: new Date()};
  expectationOptions = new Array('Probable', 'Improbable');
  modalTitle: string;

  constructor(public activeModal: NgbActiveModal,
              private alertify: AlertifyService) { }

  ngOnInit() {
    this.modalTitle = (this.gift.id > 0) ? 'Edit' : 'Create';
    if (this.gift.expectation === 'Unexpected') {
      this.expectation = 'Unexpected';
    }
    this.editGift = this.createCopyOfOriginalGift();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  saveAndClose(updatedGift: Gift) {
    this.putNewLinkIntoGiftLinks();
    this.activeModal.close(updatedGift);
  }

  deleteLink(link: Link) {
    this.alertify.confirm('Are you sure you want to delete the ' + link.name + ' link?', () => {
      const linkIndex = this.editGift.giftLinks.findIndex(l => l.id === link.id);
      this.editGift.giftLinks.splice(linkIndex, 1);
    });
  }

  createCopyOfOriginalGift() {
    return JSON.parse(JSON.stringify(this.gift));
  }

  putNewLinkIntoGiftLinks() {
    if (this.newLink.url.length > 0 && this.newLink.name.length > 0) {
      this.newLink.giftId = this.editGift.id;
      if (this.editGift.giftLinks === undefined) {
        this.editGift.giftLinks = [];
      }
      this.editGift.giftLinks.push(this.newLink);
    }
  }

  compareOption(option1: string, option2: string): boolean {
    return option1 === option2;
  }
}
