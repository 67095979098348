import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { SecretSanta } from 'src/app/_models/secretSanta';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { ActivatedRoute, Router } from '@angular/router';
import { SecretSantaInvitation } from 'src/app/_models/secretSantaInvitation';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-secret-santa-detail',
  templateUrl: './secret-santa-detail.component.html',
  styleUrls: ['./secret-santa-detail.component.css']
})
export class SecretSantaDetailComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  secretSantaId: number;
  secretSanta: SecretSanta;
  secretSantaInvitation: SecretSantaInvitation;
  wishlistMember: WishlistMember;
  email: string;
  error: any;

  constructor(private authService: AuthService,
              private alertify: AlertifyService,
              private wishlistMemberService: WishlistMemberService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.key) {
        this.wishlistMemberService.getSecretSantaInvitationFromKey(params.key).subscribe(secretSantaId => {
          this.secretSantaId = secretSantaId;
          this.initializeSecretSanta();
        }, error => {
          this.alertify.error('Error1: ' + error);
        });
      }
      this.secretSantaId = params.id;
      this.initializeSecretSanta();
    }, error => {
      this.alertify.error('Error2: ' + error);
    });
  }

  initializeSecretSanta() {
    this.wishlistMemberService.getWishlistMember(this.authService.currentMember.id).subscribe(wishlistMember => {
      if (!wishlistMember) {
        this.router.navigate(['/wishlist']);
        return false;
      }
      this.wishlistMember = wishlistMember;
      this.email = this.wishlistMember.member.logins.find(login => login.source === 'wyssweb').email;

      this.blockUI.start('Getting Secret Santa details...');
      this.wishlistMemberService.getSecretSanta(this.secretSantaId).subscribe(secretSanta => {
        this.secretSanta = secretSanta;
        this.secretSantaInvitation = secretSanta.secretSantaInvitations.find(ssi => ssi.wishlistMemberId === this.wishlistMember.id);
      }, error => {
        this.error = error;
      }).add(() => {
        this.blockUI.stop();
      });
    }, error => {
      this.error = error;
    });
  }
  respondSecretSantaInvitation(secretSantaInvitation: SecretSantaInvitation, response: boolean) {
    this.blockUI.start('Responding to Secret Santa Invitation');
    this.wishlistMemberService.respondSecretSantaInvitation(this.secretSanta, response).subscribe(data => {
      secretSantaInvitation.statusCode = data.statusCode;
      secretSantaInvitation.statusDate = data.statusDate;
      secretSantaInvitation.wishlistMemberId = data.wishlistMember.id;
      secretSantaInvitation.wishlistMember = data.wishlistMember;
    }, error => {
      this.error = error;
    }).add(() => {
      this.blockUI.stop();
    });
  }

  navigateWishlistMember(memberId: number) {

    this.blockUI.start('Getting Secret Santa details...');
    this.wishlistMemberService.getSecretSanta(this.secretSantaId).subscribe(secretSanta => {
      const today = new Date();
      const eventDate = new Date(this.secretSanta.eventDate);
      if (today > eventDate) {
        this.alertify.error('This Secret Santa has expired.');
        return false;
      }

      if (this.secretSanta.statusCode !== 'ACTIVE') {
        this.alertify.error('This Secret Santa is inactive.');
        return false;
      }
      this.router.navigate(['/wishlist/' + memberId]);
    }, error => {
      this.error = error;
    }).add(() => {
      this.blockUI.stop();
    });
    return false;
  }

  notImplemented(secretSanta: SecretSanta) {
    this.alertify.error('Not Implemented');
    return false;
  }
}
