import { Component, OnInit, ViewChild } from '@angular/core';
import { ClueChaseService } from 'src/app/_services/clueChase.service';
import { ClueChase } from 'src/app/_models/clueChase';
import { ClueChaseLocation } from 'src/app/_models/clueChaseLocation';

import { AlertifyService } from 'src/app/_services/alertify.service';
import { SortPipe } from 'src/app/_pipes/sortPipe';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, Subscription } from 'rxjs';
import { JsonpClientBackend } from '@angular/common/http';
import { AuthService } from 'src/app/_services/auth.service';
@Component({
  selector: 'app-play-game',
  templateUrl: './play-game.component.html',
  styleUrls: ['./play-game.component.scss']
})
export class PlayGameComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  clueChase: ClueChase;
  gameKey: string ='';
  myPosition = null;
  mapPosition = null;
  currentClueChaseLocation: ClueChaseLocation;
  selectedClueChaseLocation: ClueChaseLocation;
  distanceRemaining: string;
  circleRadius: number;
  enableMapSync: boolean = true;
  publicGames: ClueChase[] = null;
  publicDistance: number = 5000;
  trackType: string = "watch";
  isPolling: boolean = false;
  watchId: number = 0;
  memberId: number = null;

  sortPipe: SortPipe = new SortPipe();

  constructor(public clueChaseService: ClueChaseService,
              private alertify: AlertifyService,
              private authService: AuthService ) { }

  ngOnInit() {
    
    let clueChase = localStorage.getItem('clueChase');

    if (clueChase != null && clueChase.length > 0)  {
      this.clueChase = <ClueChase> JSON.parse(clueChase);
      if (this.clueChase.key != null) {
        this.gameKey = this.clueChase.key;
        this.startGame();
      }
      if (this.authService.currentMember != null) {
        this.memberId = this.authService.currentMember.id;
      }
      
    }
    setInterval(() => { this.findMe(); }, 3000);
    this.trackMe();
  }

  ngOnDestroy() {
    if (this.watchId > 0) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }

  findMe() {
    if (this.trackType === 'poll' && !this.isPolling) {
      this.isPolling = true;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function () {}, function () {}, {});
        navigator.geolocation.getCurrentPosition((position) => {
            this.myPosition = position;
            this.mapPosition = position;
            this.loadPublicGames();
            this.checkNextLocation();
            this.isPolling = false;
          
        }), function error(msg) {this.alertify.message('Please enable your GPS position feature.');this.isPolling = false;console.log(msg);},
        {maximumAge:5000, timeout:2500, enableHighAccuracy: true};
      } else {
        this.distanceRemaining = "Geolocation is not supported by this browser.";
      }  
    }  
  }

  trackMe() {
    // https://medium.com/@balramchavan/display-and-track-users-current-location-using-google-map-geolocation-in-angular-5-c259ec801d58
    if (navigator.geolocation) {
      this.watchId = navigator.geolocation.watchPosition(
        (position) => {
          if (this.trackType === 'watch') {
            this.myPosition = position;
            this.mapPosition = position;
            this.loadPublicGames();
            this.checkNextLocation();
          }
        }
      ) , function error(msg) {
            alert('Please enable your GPS position feature.');
          }
        , {maximumAge:10000, timeout:10000, enableHighAccuracy: true};
    } else {
      this.distanceRemaining = "Geolocation is not supported by this browser.";
    }
  }
  
  getPublicGames() {
    this.clueChaseService.getPublicGames(this.myPosition.coords.latitude, this.myPosition.coords.longitude, this.publicDistance).subscribe(result => {
      this.publicGames = result;
    }, error => {
      this.alertify.error(error);
    })
  }

  loadPublicGames() {
    if (this.publicGames == null) {
      this.getPublicGames();
    }
  }

  loadGame() {
    if (!this.gameKey || this.gameKey.length === 0) {
      this.alertify.error('You must enter a game key');
      return;
    }
    this.blockUI.start('Loading Game');
    this.clueChaseService.getGame(this.gameKey).subscribe(result => {
      this.clueChase = result;
      this.clueChase.clueChaseLocations.map((clueChaseLocation) => { clueChaseLocation.location.codeResponse = null; clueChaseLocation.location.found = false;});
      this.saveGame();
      this.startGame();      
    }, error => {
      this.gameKey = '';
      this.alertify.error(error);
    }).add(() => {
      this.blockUI.stop();
    });
  }

  startGame() {
    this.clueChase.clueChaseLocations = this.sortPipe.transform(this.clueChase.clueChaseLocations, 'sortOrder');
      //console.log(this.clueChase);
      this.currentClueChaseLocation = this.clueChase.clueChaseLocations.find(ccl => ccl.location.found == null || ccl.location.found == false);
      this.circleRadius = this.currentClueChaseLocation.location.distanceCheck;
  }

  resetClueChase() {
    this.alertify.confirmCancel('Are you sure you wish to Quit?', (confirm:boolean) => {
      if (confirm) {
        this.gameKey = '';
        this.clueChase = null;
        this.distanceRemaining = null;
        this.selectedClueChaseLocation = null;
        localStorage.setItem('clueChase', '');
      }

    }, "Confirm Quit");
  }

  getMapZoom() {
    let zoom = Math.round(14-Math.log(this.circleRadius/512)/Math.LN2);
    return zoom;
  }

  checkNextLocation() {
    if (this.clueChase == null) {
      return;
    }
    
    //console.log(this.currentClueChaseLocation.location.codeResponse.toLowerCase() !== this.currentClueChaseLocation.location.code.toLowerCase());
    if (this.currentClueChaseLocation.location.found && this.currentClueChaseLocation.location.code != null 
        && (this.currentClueChaseLocation.location.codeResponse == null || this.currentClueChaseLocation.location.codeResponse !== this.currentClueChaseLocation.location.code)) {
      this.distanceRemaining = 'Enter a response';
      return;
    }

    let nextClueChaseLocation = this.clueChase.clueChaseLocations.find(l => !l.location.found);
    if (nextClueChaseLocation == null) {
      this.gameOver();
    } else {
      this.circleRadius = nextClueChaseLocation.location.distanceCheck;
      let distance = this.asTheCrowFlies(this.myPosition, nextClueChaseLocation.location.latitude, nextClueChaseLocation.location.longitude);
      //console.log(distance);
      this.distanceRemaining = distance + ' meters';
      if (distance < nextClueChaseLocation.location.distanceCheck) {
        nextClueChaseLocation.location.found = true;
        this.clueChaseService.locationUsage(nextClueChaseLocation.location.id, this.clueChase.gameId, this.memberId).subscribe(locationUsage => {
            if (locationUsage != null) {
              var clueChaseLocationUsage = this.clueChase.clueChaseLocations.find(ccl => ccl.locationId === locationUsage.locationId);
              if (clueChaseLocationUsage != null) {
                clueChaseLocationUsage.locationUsageGameId = locationUsage.gameId;
                this.saveGame();
              }
            }
            // TODO: update the clueChaseLocation with thet locationUsage.gameId at some later time...
           
          }
        , error => {
          this.alertify.error(error);
        });
        if (nextClueChaseLocation.location.code != null) {
          this.currentClueChaseLocation = nextClueChaseLocation;
          this.selectedClueChaseLocation = nextClueChaseLocation;
        } else {
          nextClueChaseLocation = this.clueChase.clueChaseLocations.find(l => !l.location.found);
          if (nextClueChaseLocation != null) {
            this.currentClueChaseLocation = nextClueChaseLocation;
            this.circleRadius = this.currentClueChaseLocation.location.distanceCheck;
          }
        }
      }
    }
    this.saveGame();
  }

  private saveGame() {
    localStorage.setItem('clueChase', JSON.stringify(this.clueChase));
    console.log('saveGame');
  }

  private gameOver() {
    this.distanceRemaining = "Finished!";
    
    this.circleRadius = 0;
    this.clueChase.clueChaseLocations.forEach(clueChaseLocation=> {
      let distance = this.asTheCrowFlies(this.myPosition, clueChaseLocation.location.latitude, clueChaseLocation.location.longitude);
      //console.log(distance);
      if (distance > this.circleRadius) {
        this.circleRadius = distance;
      }
    })
  }

  truncate(value: number) {
    return Math.floor(value);
  }

  asTheCrowFlies(currentPosition: any, x2: number, y2: number) {
    var result = 0;
    var x1: number = currentPosition.coords.latitude;
    var y1: number = currentPosition.coords.longitude;
    const RADIANS: number = 180 / 3.14159265;
    const METRES_IN_MILE: number = 1609.34;
    
    if (x1 == x2 && y1 == y2) {
      result = 0;
    
    } else {
      // Calculating Distance between Points
      var lt1 = x1 / RADIANS;
      var lg1 = y1 / RADIANS;
      var lt2 = x2 / RADIANS;
      var lg2 = y2 / RADIANS;
    
      // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
      result = (3958.8 * METRES_IN_MILE) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
    }
    return result;
  }
  
  locationMethodChanged(method: string) {
    this.trackType = method;
  }

  actionResponse(codeResponse: string) {
    this.saveGame();
  }
}
