import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PlayGameComponent } from '../games/clue-chase/play-game/play-game.component';

@Injectable()
export class WarnCluechaseExit implements CanDeactivate<PlayGameComponent> {
    canDeactivate(component: PlayGameComponent) {
        if (component.gameKey.length > 0) {
            return confirm('Navigating away will erase your progress... Are you sure?');
        }
        return true;
    }
}
