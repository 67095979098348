<div *ngIf="this.currentWishlistMember != null">
  <div class="row" style="text-align: center">
    <div class="col-md-2">
      <button class="btn btn-primary" [routerLink]="['/wishlist/edit/', this.currentWishlistMember.member.id]">Edit My Wishlist</button>
      <button [ngClass]="{'btn btn-success': this.currentMode === 'wishlists'}" 
        (click)="setCurrentMode('wishlists');">Members</button>
      <button [ngClass]="{'btn btn-success': this.currentMode === 'giftsFrom'}" 
        (click)="setCurrentMode('giftsFrom');">Gifts I've Said I'll Get</button>
      <button [ngClass]="{'btn btn-success': this.currentMode === 'invitations'}" 
        (click)="setCurrentMode('invitations');">Invitations</button>
      <button [ngClass]="{'btn btn-success': this.currentMode === 'secretSanta'}" 
        (click)="setCurrentMode('secretSanta');">Secret Santa</button>
    </div>
    <div class="col-md-10">
      <div *ngIf="this.currentMode === 'wishlists'">
        <table class="fulltable">
          <tr>
            <td class="centertd">
              <button id=0 style="max-width:100%;"
                draggable="false" (drop)="drop($event)" (dragover)="allowDrop($event)"
                [ngClass]="{'btn btn-success': this.wishlistGroupId === 0}"
                (click)="setGroup(0);">Default</button>
            </td>
            <td class="centertd">
              <button id=1 style="max-width:100%;"
                draggable="false" (drop)="drop($event)" (dragover)="allowDrop($event)"
                [ngClass]="{'btn btn-success': this.wishlistGroupId === 1}"
                (click)="setGroup(1);">Family</button>
            </td>
            <td class="centertd">
              <button id=2 style="max-width:100%;"
                draggable="false" (drop)="drop($event)" (dragover)="allowDrop($event)"
                [ngClass]="{'btn btn-success': this.wishlistGroupId === 2}"
                (click)="setGroup(2);">Friends</button>
            </td>
            <td class="centertd">
              <button id=3 style="max-width:100%;"
                draggable="false" (drop)="drop($event)" (dragover)="allowDrop($event)"
                [ngClass]="{'btn btn-success': this.wishlistGroupId === 3}"
                (click)="setGroup(3);">Work</button>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="this.currentMode === 'wishlists'" class="container" style="overflow-y:auto; height:70vh;">
        <div class="row">
          <div *ngFor="let wishlistMember of this.members | filter: 'wishlistGroupId' : this.wishlistGroupId | exclude: 'id': this.currentWishlistMember.id" class="col-3">
            <app-wishlist-card id="wishlistMember.id" draggable="true" (dragstart)="drag($event)" [wishlistMember]="wishlistMember" (wishlistMemberRemoved)="removeWishlistMember($event)"></app-wishlist-card>
          </div>
        </div>
      </div>
      <app-wishlist-invitations *ngIf="this.currentMode === 'invitations'"></app-wishlist-invitations>
      <app-gift-list-from *ngIf="this.currentMode === 'giftsFrom'"></app-gift-list-from>
      <app-secret-santa *ngIf="this.currentMode === 'secretSanta'"></app-secret-santa>
    </div>
  </div>
</div>
<div class="container" *ngIf="this.currentWishlistMember == null">
  <div class="row">
    <app-wishlist-create [wishlistMember]="this.currentWishlistMember" (createdWishlistMember)="setCurrentWishlistMember($event)"></app-wishlist-create>
  </div>
</div>