import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BsDropdownModule, TabsModule, BsDatepickerModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
//import { NgxGalleryModule } from 'ngx-gallery';
import { BlockUIModule } from 'ng-block-ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AuthService } from './_services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './authentication/register/register.component';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { AlertifyService } from './_services/alertify.service';
import { appRoutes } from './routes';
import { AuthGuard } from './_guards/auth.guard';
import { MemberService } from './_services/member.service';
import { WishlistMemberService } from './_services/wishlistMember.service';
import { ClueChaseService } from './_services/clueChase.service';
import { WishlistComponent } from './wishlist/wishlist.component';
import { WishlistCardComponent } from './wishlist/wishlist-card/wishlist-card.component';
import { WishlistDetailComponent } from './wishlist/wishlist-detail/wishlist-detail.component';
import { WishlistMemberDetailResolver } from './_resolvers/wishlist-detail.resolver';
import { WishlistMemberListResolver } from './_resolvers/wishlist-list.resolver';
import { WishlistEditComponent } from './wishlist/wishlist-edit/wishlist-edit.component';
import { WishlistMemberEditResolver } from './_resolvers/wishlist-edit.resolver';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { ClueChaseResolver } from './_resolvers/clue-chase.resolver';
import { LocationResolver } from './_resolvers/location.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { WarnCluechaseExit } from './_guards/warn-cluechase-exit.guard';
import { PhotoEditorComponent } from './members/photo-editor/photo-editor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GiftPopupComponent } from './wishlist/gift-popup/gift-popup.component';
import { WishlistCreateComponent } from './wishlist/wishlist-create/wishlist-create.component';
import { GiftListComponent } from './wishlist/gift-list/gift-list.component';
import { PasswordComponent } from './authentication/password/password.component';
import { InvitationComponent } from './wishlist/invitation/invitation.component';
import { LoginComponent } from './authentication/login/login.component';
import { WishlistInvitationsComponent } from './wishlist/wishlist-invitations/wishlist-invitations.component';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { SharedModule } from './shared/shared.module';
import { GiftListFromComponent } from './wishlist/gift-list-from/gift-list-from.component';
import { MemberChildrenComponent } from './members/member-children/member-children.component';
import { MemberParentComponent } from './members/member-parent/member-parent.component';
import { ParentInvitationsResolver } from './_resolvers/parent-invitations.resolver';
import { ParentInvitationResolver } from './_resolvers/parent-invitation.resolver';
import { ParentInvitationComponent } from './members/parent-invitation/parent-invitation.component';
import { MaterialModule } from './material.module';
import { DragComponent } from './drag/drag.component';
import { SecretSantaComponent } from './wishlist/secret-santa/secret-santa.component';
import { SecretSantaDetailComponent } from './wishlist/secret-santa/secret-santa-detail/secret-santa-detail.component';
import { SecretSantaEditComponent } from './wishlist/secret-santa/secret-santa-edit/secret-santa-edit.component';
import { LaserduelComponent } from './games/laserduel/laserduel.component';
import { VivecraftComponent } from './games/vivecraft/vivecraft.component';
import { GamesComponent } from './games/games.component';
import { ClueChaseModule } from './games/clue-chase/clue-chase.module'; 
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

export function tokenGetter() {
   return localStorage.getItem('token');
}
@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      DragComponent,
      HomeComponent,
      MemberEditComponent,
      MemberChildrenComponent,
      MemberParentComponent,
      ParentInvitationComponent,
      RegisterComponent,
      LoginComponent,
      ConfirmComponent,
      PasswordComponent,
      LaserduelComponent,
      WishlistComponent,
      WishlistCardComponent,
      WishlistCreateComponent,
      WishlistDetailComponent,
      WishlistEditComponent,
      WishlistInvitationsComponent,
      InvitationComponent,
      GamesComponent,
      VivecraftComponent,
      GiftPopupComponent,
      GiftListComponent,
      GiftListFromComponent,
      SecretSantaComponent,
      SecretSantaDetailComponent,
      SecretSantaEditComponent,
      PhotoEditorComponent
   ],
   imports: [
      BrowserModule,
      SharedModule,
      HttpClientModule,
      BsDropdownModule.forRoot(),
      BsDatepickerModule.forRoot(),
      TabsModule.forRoot(),
      NgbModule,
      RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
      //NgxGalleryModule,
      JwtModule.forRoot({
         config: {
            tokenGetter,
            whitelistedDomains: ['localhost:5000'],
            blacklistedRoutes: ['localhost:5000/api/auth']
         }
      }),
      BlockUIModule.forRoot(),
      DragDropModule,
      MaterialModule,
      ClueChaseModule,
      SocialLoginModule,
      BrowserAnimationsModule     
   ],
   entryComponents: [GiftPopupComponent],
   providers: [
      AuthService,
      ErrorInterceptorProvider,
      AlertifyService,
      AuthGuard,
      PreventUnsavedChanges,
      WarnCluechaseExit,
      MemberService,
      WishlistMemberService,
      ClueChaseService,
      WishlistMemberDetailResolver,
      WishlistMemberListResolver,
      WishlistMemberEditResolver,
      MemberEditResolver,
      ParentInvitationResolver,
      ParentInvitationsResolver,
      ClueChaseResolver,
      LocationResolver,
      {
         provide: 'SocialAuthServiceConfig',
         useValue: {
           autoLogin: false,
           providers: [
             {
               id: GoogleLoginProvider.PROVIDER_ID,
               provider: new GoogleLoginProvider(
                 '901513166970-tje861p33v958oo2oefiprgalandto9m.apps.googleusercontent.com'
               )
             },
             {
               id: FacebookLoginProvider.PROVIDER_ID,
               provider: new FacebookLoginProvider('175633272520167')
             }
           ]
         } as SocialAuthServiceConfig,
       }

   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
