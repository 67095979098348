<block-ui>
    <div class="container mt-4">        
        <form #gameForm name="gameForm" (submit)="loadGame()">
            <div class="row">
                <div class="col"><h4>Clue Chase</h4></div>
                <div *ngIf="clueChase==null" class="col"><input type="text" style="max-width: 100px;" name="gameKey" [(ngModel)]="gameKey" placeHolder="Enter Game Key"/></div>
                <div *ngIf="clueChase==null" class="col"><button type="submit" class="btn btn-success">Go!</button></div>
                <div *ngIf="clueChase!=null" class="col"><button type="reset" class="btn btn-danger" (click)="resetClueChase()">Quit</button></div>
            </div>
            <div class="row" *ngIf="myPosition!=null && clueChase==null">
                <div class="col">Max Distance</div>
                <div class="col"><input type="text" name="maxDistance" [(ngModel)]="publicDistance"></div>
                <div class="col"><button type="button" (click)="getPublicGames()">Search</button></div>
            </div>
        </form>
        <table *ngIf="myPosition!=null && publicGames!=null && clueChase==null" style="width:100%;" cellspacing="0" cellpadding="0">
            <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Distance (meters)</th>
                <th>Size (meters)</th>
            </tr>
            <tr *ngIf="publicGames.length === 0" >
                <td colspan="3">No matching games</td>
            </tr>
            <tr *ngFor="let clueChase of publicGames" (click)="gameKey=clueChase.key;loadGame(clueChase.key)">
                <td>{{clueChase.name}}</td>
                <td>{{clueChase.statusCode}}</td>
                <td>{{truncate(asTheCrowFlies(myPosition, clueChase.latitude, clueChase.longitude))}} meters</td>
                <!--<td></td>-->
                <td>{{clueChase.radius}} meters</td>
            </tr>
        </table>
        <table *ngIf="clueChase!=null" style="width:100%;" cellspacing="0" cellpadding="0">
            <tr>
                <th>Name</th>
                <th>Key</th>
                <th>Status</th>
            </tr>
            <tr>
                <td>{{clueChase.name}}</td>
                <td>{{clueChase.key}}</td>
                <td>{{clueChase.statusCode}}</td>
            </tr>
            <tr><td colspan="3">{{clueChase.description}}</td></tr>
        </table>
        <div class="row">
            <div class="col-sm-12">
                <app-location-list [clueChaseLocations]="clueChase?.clueChaseLocations" (actionResponse)="actionResponse($event)"></app-location-list>
            </div>
        </div>
        <div *ngIf="myPosition!==null && distanceRemaining!=null" class="row">
            {{distanceRemaining}}
        </div>
        <!-- <form #locationOptions name="locationOptions">
            <div class="container">
                <div class="row">
                    <input type="radio" name="trackType" [(ngModel)] = "trackType" [value]="'poll'" class="" [ngModelOptions]="{standalone: true}"/>
                    Poll&nbsp;&nbsp;&nbsp;
                    <input type="radio" name="trackType" [(ngModel)] = "trackType" [value]="'watch'" [ngModelOptions]="{standalone: true}"/>
                    Watch
                </div>
            </div>
        </form> -->
        <div class="row" *ngIf="clueChase!=null">
            <div class="col-sm-12">
                <app-map-display 
                    [mapPosition]="mapPosition" 
                    [clueChaseLocations]="clueChase?.clueChaseLocations" 
                    [circleRadius]="circleRadius"
                    [zoom]="getMapZoom()"
                    [showTrackType]="true"
                    (locationMethodChanged)="locationMethodChanged($event)"></app-map-display>
            </div>
        </div>
    </div>
</block-ui>
