<table width="100%">
    <tr *ngFor="let clueChaseLocation of clueChaseLocations;let indexOfElement=index;">
        <td style="width:25%;vertical-align: top;">
            <span *ngIf="editMode" class="fa fa-trash" (click)="delete(clueChaseLocation)"></span>
            <img [ngClass]="{'fullScreen': clueChaseLocation.location.url === showImage}" (click)="setShow(clueChaseLocation.location.url)"
                class="card-img-top img-thumbnail" src="{{(editMode || clueChaseLocation.location.found)?clueChaseLocation.location.url:'../../assets/question.png'}}">
        </td>
        <td style="width:75%;vertical-align: top;">
            <table>
                <tr (click)="toggleAction(clueChaseLocation)">
                    <td>
                        <div *ngIf="editMode
                            || indexOfElement === 0 
                            || (clueChaseLocations[indexOfElement-1].location.found 
                                && (clueChaseLocations[indexOfElement-1].location.code == null 
                                    || clueChaseLocations[indexOfElement-1].location.code === clueChaseLocations[indexOfElement-1].location.codeResponse
                                    )
                                )">
                                {{ clueChaseLocation.location.hint }}
                        </div>
                    </td>
                </tr>
                <tr (click)="toggleAction(clueChaseLocation)"><td [ngClass]="{'viewCode': clueChaseLocation.location.code != null}" class="locationName">{{(editMode || clueChaseLocation.location.found)?clueChaseLocation.location.name:''}}</td></tr>
                <tr><td>
                    {{ clueChaseLocation.locationUsageGameId }}
                    <app-location-action [clueChaseLocation]="clueChaseLocation" [showAction]="clueChaseLocation.location.showAction" [editMode]="editMode" (codeResponseMatched)="codeResponseMatched($event)"></app-location-action></td></tr>
            </table>
        </td>
    </tr>
</table>

