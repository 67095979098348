<block-ui>
  <div class="container mt-4">
    <div class="row">
      <h1>
          {{wishlistMember?.member.knownAs}}
      </h1>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="card">
          <img class="card-img-top img-thumbnail" src="{{wishlistMember?.member.photoUrl || '../../assets/user.png'}}" alt="{{wishlistMember?.member.knownAs}}">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <strong>Birthday</strong>
                <p>{{wishlistMember?.birthMonth}}/{{wishlistMember?.birthDom}}/yyyy</p>
              </div>
              <div class="col">
                <strong>Last Changed</strong>
                <p>{{wishlistMember?.lastChanged | date}}</p>
              </div>
            </div>

            <div>
              <strong>Shipping Address</strong>
              <p>{{wishlistMember?.shippingAddress}}</p>
            </div>
            <div>
              <strong>Favorite Color</strong>
              <p>{{wishlistMember?.favoriteColor}}</p>
            </div>
            <div>
              <strong>Pant Size</strong>
              <p>{{wishlistMember?.pantSize}}</p>
            </div>
            <div>
              <strong>Shirt Size</strong>
              <p>{{wishlistMember?.shirtSize}}</p>
            </div>
            <div>
              <strong>Shoe Size</strong>
              <p>{{wishlistMember?.shoeSize}}</p>
            </div>
            <div>
              <strong>Clothing Misc</strong>
              <p>{{wishlistMember?.clothingMisc}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
          <div class="tab-panel">
            <tabset class="member-tabset">
              <tab heading="Gifts">
                <h4>Gift List:</h4>
                <app-gift-list [wishlistMember]="this.wishlistMember" [currentWishlistMember]="this.currentWishlistMember"></app-gift-list>
              </tab>
              <tab heading="Interests">
                <h4>Interests:</h4>
                <p>{{wishlistMember?.interests}}</p>
              </tab>
              <tab heading="Hobbies">
                <h4>Hobbies:</h4>
                <p>{{wishlistMember?.hobbies}}</p>
              </tab>
            </tabset>
          </div>
      </div>
    </div>
  </div>
</block-ui>