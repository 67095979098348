<div class="card-img-wrapper">
    <img *ngIf="currentMemberId === this.wishlistMember.member.id" [id]="this.wishlistMember.id" class="card-img-top"
      [routerLink]="['/wishlist/edit', this.wishlistMember.member.id]"
      src="{{wishlistMember?.member.photoUrl || '../../assets/user.png'}}" 
      alt="{{wishlistMember?.member.knownAs}}">
    <img *ngIf="currentMemberId !== this.wishlistMember.member.id" [id]="this.wishlistMember.id" class="card-img-top"
      [routerLink]="['/wishlist/', this.wishlistMember.member.id]"
      src="{{wishlistMember?.member.photoUrl || '../../assets/user.png'}}" 
      alt="{{wishlistMember?.member.knownAs}}">
</div>
<div class="card-body p-1">
  <h6 class="card-title text-center mb-1">
    <i *ngIf="!this.wishlistMember.member.isChild" class="fa fa-user"></i>
    {{wishlistMember.member.knownAs}}
     <!-- {{ wishlistMember.member.isChild ? '(Child)' : '' }}<br> -->
    <!-- Birthday: {{ wishlistMember.birthMonth}}/{{wishlistMember.birthDom}}/yyyy<br>
    Last Changed: {{ wishlistMember.lastChanged | date: 'MM/dd/yyyy' }} -->
    <div class="container">
      <div class="row justify-content-center">
        <button *ngIf="this.wishlistMember.memberID != this.currentMemberId && !this.wishlistMember.member.isChild" class="btn btn-danger" (click)="this.removeWishlistMember()">Remove</button>
        <button *ngIf="this.wishlistMember.memberID != this.currentMemberId && this.wishlistMember.member.isChild" class="btn btn-child" (click)="this.removeChild(this.wishlistMember)">Remove</button>
        <button *ngIf="this.wishlistMember.memberID == this.currentMemberId" class="btn btn-success" [routerLink]="['/wishlist/edit/', this.wishlistMember.memberID]">Edit</button>
      </div>
    </div>
  </h6>
</div>