import { Component, EventEmitter, Input, OnInit, Output, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClueChaseLocation } from 'src/app/_models/clueChaseLocation';
@Component({
  selector: 'app-location-action',
  templateUrl: './location-action.component.html',
  styleUrls: ['./location-action.component.scss']
})
export class LocationActionComponent implements OnInit {
  @Input()
  clueChaseLocation: ClueChaseLocation;
  @Input()
  showAction: boolean;
  @Input()
  editMode: boolean;

  @Output() codeResponseMatched = new EventEmitter<string>();
  
  showImage: string = null;
  
  constructor() { }

  ngOnInit() {
  }

  setShow(image: string) {
    if (this.showImage === null) {
      this.showImage = image;
    } else {
      this.showImage = null;
    }
  }

  changedResponse(event: any) {
    if (<string>event.toLowerCase() === this.clueChaseLocation.location.code.toLowerCase()) {
      this.clueChaseLocation.location.codeResponse = this.clueChaseLocation.location.code.toLowerCase();
      this.codeResponseMatched.emit(event);
    }
  }
}
