import { Component, OnInit, ViewChild, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Member } from 'src/app/_models/member';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { NgForm } from '@angular/forms';
import { MemberService } from 'src/app/_services/member.service';
import { AuthService } from 'src/app/_services/auth.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Login } from 'src/app/_models/login';
import { Photo } from 'src/app/_models/photo';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.css']
})
export class MemberEditComponent implements OnInit {
  @ViewChild('editForm', { static: true }) editForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  loaded = false;
  member: Member;
  photoUrl: string;

  wysswebLogin: Login = null;
  googleLogin: Login = null;
  fbLogin: Login = null;

  authStateSubscription: any;
  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //   $event.returnValue = true;
  // }
  constructor(private route: ActivatedRoute, private alertify: AlertifyService,
              private memberService: MemberService, private authService: AuthService,
              private socialAuthService: SocialAuthService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.member = data.member;
      this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
      this.photoUrl = this.member.photoUrl;
      this.wysswebLogin = this.member.logins.find(l => l.source === 'wyssweb');
      this.googleLogin = this.member.logins.find(l => l.source === 'GOOGLE');
      this.fbLogin = this.member.logins.find(l => l.source === 'FACEBOOK');

      this.authStateSubscription = this.socialAuthService.authState.subscribe((user) => {
        if (user.provider === 'GOOGLE' && this.googleLogin != null) {
          return;
        }
        if (user.provider === 'FACEBOOK' && this.fbLogin != null) {
          return;
        }
        this.blockUI.start('Loggin in');
        this.authService.socialRegister(user).subscribe(
          (login: Login) => {
            console.log(login);
              if (login.source === 'GOOGLE') {
                this.googleLogin = login;
              } else {
                this.fbLogin = login;
              }
              
          },
          error => {
              // login failed so display error
              this.alertify.error(error);
              this.loaded = true;
          }
        ).add(() => {
          this.blockUI.stop();
        });
      });
    });
  }

  ngOnDestroy() {
    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
  }
  updateMember() {
    this.blockUI.start('Updating Member Profile');
    this.memberService.updateMember(this.member.id, this.member).subscribe(next => {
      this.alertify.success('Profile updated successfully');
      this.editForm.reset(this.member);
      this.blockUI.stop();
    }, error => {
      this.alertify.error('updateMember: ' + error);
      this.blockUI.stop();
    });
  }

  updateMainPhoto(photoUrl) {
    this.photoUrl = photoUrl;
    this.member.photoUrl = photoUrl;
  }

  sendLoginConfirmation(login: Login) {
    this.authService.sendLoginConfirmation(login).subscribe(() => {
      this.alertify.success('Confirmation email successfully sent to ' + login.email);
    }, error => {
      this.alertify.error('sendLoginConfirmation: ' + error);
    });
    return false;
  }

  sendPasswordReset(login: Login) {
    // tslint:disable-next-line: max-line-length
    this.alertify.confirm('A link to reset your password will be mailed to ' + login.email + ', and will expire in 1 hour... are you ready to check your email?', () => {
      this.authService.sendPasswordReset(login.email).subscribe(() => {
        this.alertify.success('Password reset link sent to ' + login.email);
      }, error => {
        this.alertify.error('sendPasswordReset: ' + error);
      });
    });
  }

  removeSocialLogin(login: Login) {
    this.alertify.confirm('Are you sure you want to remove ' + login.email + '?', () => {
      this.authService.deleteLogin(login).subscribe(login => {
        if (login.source === 'hintchase') {
          this.wysswebLogin = null;
        } else if (login.source === 'GOOGLE') {
          this.googleLogin = null;
        } else if (login.source === 'FACEBOOK') {
          this.fbLogin = null;
        }
      })
    })
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
