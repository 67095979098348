<div class="warning" *ngIf="refresh===true">
    Some of the locations have changed - please refresh your browser
</div>
<table width="100%"  *ngIf="editClueChase == null"> 
    <tr>
        <th>Name</th>
        <th>Key</th>
        <th>Event Date</th>
        <th>Status</th>
        <th>Size</th>
    </tr>
    <tr *ngFor="let clueChase of clueChases" (click)="selectGame(clueChase)">
        <td style="color: blue; text-decoration: underline;">{{clueChase.name}}</td>
        <td>{{clueChase.key}}</td>
        <td>{{clueChase.eventDate | date}}</td>
        <td>{{clueChase.statusCode}}</td>
        <td>{{clueChase.radius}} meters</td>
    </tr>
    <tr>
        <td colspan="5"><button type="button" class="btn btn-success" (click)="createGame()">Create Game</button></td>
    </tr>
</table>

<div class="container" *ngIf="editClueChase != null">
    <form #editForm name="editForm">
        <table style="width:100%">
            <tr><th style="background-color:blue;color:white;text-align: center;" colspan="3">Edit Game</th></tr>
            <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Date</th>
            </tr>
            <tr>
                <td>
                    <input type="text" name="name" [(ngModel)]="editClueChase.name" placeholder="A short name..."/>
                </td>
                <td>
                    <select name="statusCode" [(ngModel)]="editClueChase.statusCode">
                        <option [value]="'PENDING'">Pending</option>
                        <option [value]="'ACTIVE'">Active</option>
                    </select>
                </td>
                <td>
                    <input type="date" name="eventDate" class="form-control"
                    [ngModel]="this.editClueChase.eventDate | date:'yyyy-MM-dd'"
                    (ngModelChange)="updateDate($event)">
                </td>
            </tr>
            <tr>
                <th colspan="3">Description</th>
            </tr>
            <tr>
                <td colspan="3">
                    <textarea width=500 name="description" [(ngModel)]="editClueChase.description"
                    class="form-control" style="min-width: 100%" placeholder="More detail... really sell your game!"></textarea>
                </td>
            </tr>
        </table>
        <div class="row">
            <div class="col"><button [ngClass]="{'btn-success': this.isDirty}" class="btn" (click)="updateGame()">Save</button></div>
            <div class="col"><button class="btn btn-warning" (click)="cancelUpdate()">Cancel</button></div>
            <div class="col"><button *ngIf="this.editClueChase.id !== 0" class="btn btn-danger" (click)="deleteGame()">Delete</button></div>
        </div>
    </form>
    <div class="container" *ngIf="this.editClueChase.id > 0">
        <div class="row">List of Clues</div>
        <div class="row" *ngIf="this.mapPosition == null">Waiting for current position...</div>
        <div class="row" *ngIf="this.mapPosition != null">
            <button (click)="addLocation()">Add Clue</button>
        </div>
        <app-location-list [editMode]=true [clueChaseLocations]="editClueChase?.clueChaseLocations" (deleteClueChaseLocation)="deleteClueChaseLocation($event)"></app-location-list>           
    </div>


    <app-map-display *ngIf="editClueChase != null"
        [mapPosition]="mapPosition" 
        [clueChaseLocations]="editClueChase?.clueChaseLocations" 
        [circleRadius]="circleRadius"
        [zoom]="mapZoom"
        [editMode]=true
        [showTrackType]="this.mapPosition == null"
        (locationMethodChanged)="locationMethodChanged()"></app-map-display>
</div>