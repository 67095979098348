<block-ui>
  <div class="container">
    <div class="alert alert-warning" *ngIf="this.error != null">{{this.error}}</div>
    <div *ngIf="this.secretSanta">
      <form #secretSantaForm="ngForm" id="secretSantaForm" (ngSubmit)="updateSecretSanta(secretSantaForm)">
      <table cellpadding="3" cellspacing="3">
        <tr>
          <th colspan="5" class="column-header">
            Secret Santa Detail
          </th>
        </tr>
        <tr>
          <th>Event</th>
          <th>Event Date</th>
          <th>Hosted By</th>
          <th>Status</th>
          <th>Status Date</th>
        </tr>
        <tr>
          <td>
            <input type="text" name="event" class="form-control" [disabled]="this.secretSanta.statusCode !== 'PENDING' && this.secretSanta.statusCode !== 'NEW'"
              [(ngModel)]="this.secretSanta.event">
          </td>
          <td>
              <input type="date" name="eventDate" class="form-control" [disabled]="this.secretSanta.statusCode !== 'PENDING' && this.secretSanta.statusCode !== 'NEW'"
              [ngModel]="this.secretSanta.eventDate | date:'yyyy-MM-dd'"
              (ngModelChange)="updateDate($event)">
          </td>
          <td>{{ this.secretSanta.wishlistMember.member.knownAs }} </td>
          <td>{{ this.secretSanta.statusCode }}</td>
          <td>{{ this.secretSanta.statusDate | date:'medium' }}</td>
          <td>
          </td>
        </tr>
        <tr>
          <td colspan="5" class="description_row">
            <textarea name="description" class="form-control" rows="3" [disabled]="this.secretSanta.statusCode !== 'PENDING' && this.secretSanta.statusCode !== 'NEW'"
              [(ngModel)]="this.secretSanta.description"></textarea> 
          </td>
        </tr>
      </table>
      <ng-container *ngIf="this.secretSanta.statusCode === 'PENDING' || this.secretSanta.statusCode === 'NEW'">
        <div class="alert alert-warning" *ngIf="this.eventDateHasPassed">
            You have specified a passed Event Date - this will Inactivate the Secret Santa, and you will not be able to make any more changes.
        </div>
        
        <button [disabled]="this.secretSantaForm.dirty === false" type="submit" class="btn btn-primary">Save Changes</button>
        <button *ngIf="(this.secretSanta.secretSantaInvitations | exclude: 'statusCode' : ['ACCEPTED', 'REJECTED']).length === 0 && this.secretSanta.secretSantaInvitations.length > 1"
          class="btn btn-primary" (click)="drawNamesFromAHat();">Draw Names From A Hat</button>
      </ng-container>
      
      <button class="btn btn-danger" (click)="deleteSecretSanta();">Delete</button>

    </form>
      <table>
        <tr *ngIf="this.secretSantaInvitation?.recipientWishlistMemberId">
          <th>Recipient:</th>
          <td>
            <div class="image-box">
              <img (click)="this.navigateWishlistMember(this.secretSantaInvitation?.recipientWishlistMember.memberID)"
                draggable="false" src="{{this.secretSantaInvitation?.recipientWishlistMember?.member.photoUrl || '../../assets/user.png'}}" alt="">
            </div>
            <span *ngIf="this.secretSantaInvitation?.recipientWishlistMember">
              {{secretSantaInvitation?.recipientWishlistMember?.member.knownAs}}
            </span>
          </td>
          <td colspan="2"></td>
        </tr>
      </table>
      <table *ngIf="this.secretSanta.id > 0">
        <tr>
          <th colspan="6">Participants</th>
        </tr>
        <tr *ngIf="(this.secretSanta.statusCode === 'PENDING') && (this.secretSanta.secretSantaInvitations | exclude: 'statusCode' : ['ACCEPTED', 'REJECTED']).length > 0">
          <td colspan="6" class="error">
            All participants must have responded with ACCEPTED or REJECTED ({{ (this.secretSanta.secretSantaInvitations | exclude: 'statusCode' : ['ACCEPTED', 'REJECTED']).length }} remaining).
          </td>
        </tr>
        <tr *ngIf="(this.secretSanta.statusCode === 'PENDING') && (this.secretSanta.secretSantaInvitations | filter: 'statusCode' : 'ACCEPTED').length < 2" >
          <td colspan="6" class="error">
            There must be at least 2 participants who have ACCEPTED
          </td>
        </tr> 
        <tr *ngIf="(this.secretSanta.statusCode === 'PENDING') && (this.secretSanta.secretSantaInvitations | filter: 'statusCode' : 'ACCEPTED').length < 4" >
          <td colspan="6" class="warning">
            WARNING: there must be at least 4 participants who have ACCEPTED if you want the exchange to be a secret!<br/>
            &nbsp;&nbsp;&nbsp;<i>Since no participant can be assigned to themself, 2 or 3 participants reveals the exchange!</i>
          </td>
        </tr> 
        <tr *ngIf="(this.secretSanta.statusCode === 'PENDING') && (this.secretSanta.secretSantaInvitations | filter: 'email' : this.email).length === 0" >
          <td colspan="6" class="optional">
            You must invite yourself if you wish to participate.
          </td>
        </tr>      
        <tr *ngIf="this.secretSanta.secretSantaInvitations.length > 0" >
          <th>Email</th>
          <th>Accepted By</th>
          <th>Status</th>
          <th>Date</th>
          <th *ngIf="this.secretSanta.statusCode !== 'ACTIVE'" >Actions</th>
        </tr>
        <tr *ngFor="let secretSantaInvitation of secretSanta.secretSantaInvitations">
          <td>{{secretSantaInvitation.email}}</td>
          <td>
            <img *ngIf="secretSantaInvitation.wishlistMember != null" draggable="false" src="{{secretSantaInvitation.wishlistMember?.member.photoUrl || '../../assets/user.png'}}" alt="">
            <br/>
            {{secretSantaInvitation.wishlistMember?.member.knownAs}}
          </td>
          <td>{{secretSantaInvitation.statusCode}}</td>
          <td>{{secretSantaInvitation.statusDate | date: 'medium'}}</td>
          <td>
            <ng-container *ngIf="this.email === secretSantaInvitation.email && (secretSantaInvitation.statusCode === 'SENT' || secretSantaInvitation.statusCode === 'RESENT')">
              <button class="btn btn-primary" (click)="respondSecretSantaInvitation(secretSantaInvitation, true);">Accept</button>
              <button class="btn btn-danger" (click)="respondSecretSantaInvitation(secretSantaInvitation, false);">Reject</button>
            </ng-container>
            <button *ngIf="this.secretSanta.statusCode === 'PENDING' && (secretSantaInvitation.statusCode === 'SENT' || secretSantaInvitation.statusCode === 'RESENT')" 
              class="btn btn-primary" (click)="resendSecretSantaInvitation(secretSantaInvitation);">Resend</button>
            <button *ngIf="this.secretSanta.statusCode === 'PENDING' || secretSantaInvitation.statusCode === 'REJECTED'" class="btn btn-danger" (click)="deleteSecretSantaInvitation(secretSantaInvitation);">Remove</button>
          </td>
        </tr>
      </table>
      <table>
        <tr *ngIf="this.secretSanta.id > 0 && this.secretSanta.statusCode === 'PENDING'" >
          <th>
            New emails:
          </th>
          <td style="text-align: left;">
            <input type="text" style="width:200px;" placeholder="Email addresses, separated by ;"
            [(ngModel)]="this.newSecretSantaInvitations">
          </td>
          <td>
              <button [disabled]="this.newSecretSantaInvitations.length === 0" 
                (click)="addInvitations()"
                type="submit" class="btn btn-primary">Add</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</block-ui>