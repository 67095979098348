import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { WishlistInvitation } from 'src/app/_models/wishlistInvitation';
import { InvitationResult } from 'src/app/_models/invitationResult';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit {
  isLoaded = false;
  key: string;
  invitation: WishlistInvitation;
  error: string;
  wishlistMember: WishlistMember;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private wishlistMemberService: WishlistMemberService,
              private authService: AuthService,
              private alertify: AlertifyService) { }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.key = data.key;
      this.wishlistMemberService.getInvitation(this.key).subscribe(result => {
        this.invitation = result;
        this.wishlistMemberService.getWishlistMember(this.authService.currentMember.id).subscribe(wishlistMember => {
          this.wishlistMember = wishlistMember;
          this.isLoaded = true;
        }, error => {
          this.error = error;
        });

      }, error => {
        this.error = error;
      });
    });
  }

  acceptInvitation() {
    this.wishlistMemberService.acceptInvitation(this.invitation).subscribe(result => {
      this.authService.setConfirmLogin();
      this.router.navigate(['wishlist']);
    }, error => {
      this.error = error;
    });
  }

  rejectInvitation() {
    this.wishlistMemberService.rejectInvitation(this.invitation).subscribe(result => {
      this.authService.setConfirmLogin();
      this.router.navigate(['wishlist']);
    }, error => {
      this.error = error;
    });
  }
  setWishlistMember(wishlistMember: WishlistMember) {
    this.wishlistMember = wishlistMember;
  }
}
