import { Component, OnInit, Output, EventEmitter, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from 'src/app/_models/login';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  registerForm: FormGroup;
  model: Login;
  loading = true;
  returnUrl: string;
  passwordMinLength = 6;
  passwordMaxLength = 30;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private alertify: AlertifyService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    if (this.returnUrl.startsWith('/wishlist/invitation/')) {
      const key = this.returnUrl.substring(21);
      this.authService.getLogin(key, 'wishlist').subscribe(login => {
        this.model = login;
        this.createRegisterForm();
        if (this.model.id === 0) {
          // New Login
          this.registerForm.get('email').setValue(this.model.email);
          this.registerForm.get('email').disable();
          this.registerForm.get('key').setValue(key);
        }
        this.loading = false;
      }, error => {
        this.alertify.error(error);
      });
    } else if (this.returnUrl.startsWith('/member/parent/invitation/')) {
      const key = this.returnUrl.substring(26);
      this.authService.getLogin(key, 'parent').subscribe(login => {
        this.model = login;
        this.createRegisterForm();
        if (this.model.id === 0) {
          // New Login
          this.registerForm.get('email').setValue(this.model.email);
          this.registerForm.get('email').disable();
          this.registerForm.get('key').setValue(key);
        }
        this.loading = false;
      }, error => {
        this.alertify.error(error);
      });
    } else if (this.returnUrl.startsWith('/secretSantaInvitation/')) {
      const key = this.returnUrl.substring(23);
      this.authService.getLogin(key, 'secretSanta').subscribe(login => {
        this.model = login;
        this.createRegisterForm();
        if (this.model.id === 0) {
          // New Login
          this.registerForm.get('email').setValue(this.model.email);
          this.registerForm.get('email').disable();
          this.registerForm.get('key').setValue(key);
        }
        this.loading = false;
      }, error => {
        this.alertify.error(error);
      });
    } else {
      this.createRegisterForm();
      this.loading = false;
    }
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]],
      confirmPassword: ['', Validators.required],
      knownAs: ['', Validators.required],
      key: ['']
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : { mismatch : true};
  }

  register() {
    this.blockUI.start('Submitting Registration');
    this.authService.register(this.registerForm.getRawValue()).subscribe(() => {
      this.loginNewMember();
    }, error => {
      this.alertify.error(error);
      this.blockUI.stop();
    });
  }

  loginNewMember() {
    const newAccount = {
      email: this.registerForm.get('email').value,
      password: this.registerForm.get('password').value
    };
    this.authService.login(newAccount).subscribe(() => {
      this.router.navigateByUrl(this.returnUrl);
    },
    error => {
        this.alertify.error(error);
    }).add(() => {
      this.blockUI.stop();
    });
  }
}
