<block-ui>
  <div class="container">
    <div *ngIf="this.secretSantasHosting || this.secretSantasInvited">
      <table cellpadding="3" cellspacing="3">
        <tr style="background-color:lightgrey;">
          <td>
            <div style="width:auto;text-align:left;">
              Once names are exchanged in a Secret Santa, the person who is assigned to you will be able to
              <ul>
                <li>View your wishlist details</li>
                <li>View your gift list</li>
                <li>Claim a gift on your gift list</li>
                <li>Add a secret gift to your gift list</li>
              </ul>for the duration of the Secret Santa.  
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <a href="javascript:void(0)" (click)="toggleShowActiveInvited()">
                <span *ngIf="this.showActiveInvited">Active</span>
                <span *ngIf="!this.showActiveInvited">Inactive</span>
            </a>
            Secret Santas You Have Been Invited To
          </th>
        </tr>            
      </table>
      <table cellpadding="3" cellspacing="3" *ngIf="this.secretSantasInvited.length > 0">
        <tr>
          <th>Event</th>
          <th>Event Date</th>
          <th>Status</th>
          <th>Participants</th>
          <!-- <th>Status Date</th> -->
          <th>Action</th>
        </tr>
        <ng-container *ngFor="let secretSanta of this.secretSantasInvited | filter: 'statusCode': this.invitedStatusCodes">
        <tr>
          <td>{{ secretSanta.event }}</td>
          <td>{{ secretSanta.eventDate | date }}</td>
          <td>{{ secretSanta.statusCode }}</td>
          <td>{{ secretSanta.secretSantaInvitations.length }}</td>
          <!-- <td>{{ secretSanta.statusDate | date:'medium' }}</td> -->
          <td>
            <button class="btn btn-primary" [routerLink]="['/secretSanta/', secretSanta.id]">View</button>
          </td>
        </tr>
        <tr>
          <td colspan="5" class="description_row">{{ secretSanta.description }}</td>
        </tr>
        </ng-container>
      </table>
      <hr>
      <table cellpadding="3" cellspacing="3" *ngIf="this.secretSantasHosting.length > 0">
        <tr>
          <th colspan="5" class="column-header">
            <a href="javascript:void(0)" (click)="toggleShowActiveHosting()">
                <span *ngIf="this.showActiveHosting">Active</span>
                <span *ngIf="!this.showActiveHosting">Inactive</span>
            </a>
            Secret Santas You Are Hosting
          </th>
        </tr>
        <tr>
          <th>Event</th>
          <th>Event Date</th>
          <th>Status</th>
          <!-- <th>Status Date</th> -->
          <th>Participants</th>
          <th>Action</th>
        </tr>
        <ng-container *ngFor="let secretSanta of this.secretSantasHosting | filter: 'statusCode': this.hostingStatusCodes">
        <tr>
          <td>{{ secretSanta.event }}</td>
          <td>{{ secretSanta.eventDate | date }}</td>
          <td>{{ secretSanta.statusCode }}</td>
          <!-- <td>{{ secretSanta.statusDate | date:'medium' }}</td> -->
          <td>{{ secretSanta.secretSantaInvitations.length }}</td>
          <td>
              <button class="btn btn-primary" [routerLink]="['/secretSanta/edit/', secretSanta.id]">Edit</button>
          </td>
        </tr>
        <tr>
          <td colspan="5" class="description_row">{{ secretSanta.description }}</td>
        </tr>
        </ng-container>
      </table>
      <table cellpadding="3" cellspacing="3">
        <tr>
          <td>
            <button class="btn btn-primary" [routerLink]="['/secretSanta/edit/', 0]">New Secret Santa</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</block-ui>