import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-clue-chases',
  templateUrl: './my-clue-chases.component.html',
  styleUrls: ['./my-clue-chases.component.scss']
})
export class MyClueChasesComponent implements OnInit {

  refresh: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  refreshClueChases(result: boolean) {
    this.refresh = true;
  }
}
