import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'exclude',
    pure: false
})
export class ExcludePipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any[] {
        if (!items) { return []; }
        if (Array.isArray(value)) {
            value.map(v => {
                items = items.filter(it => it[field] !== v);
            });
            return items;
        } else {
            return items.filter(it => it[field] !== value);
        }
    }
}
