import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WishlistMember } from 'src/app/_models/wishlistMember';
import { WishlistMemberService } from 'src/app/_services/wishlistMember.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { Gift } from 'src/app/_models/gift';
import { Member } from 'src/app/_models/member';
@Component({
  selector: 'app-wishlist-detail',
  templateUrl: './wishlist-detail.component.html',
  styleUrls: ['./wishlist-detail.component.css']
})
export class WishlistDetailComponent implements OnInit {
  wishlistMember: WishlistMember;
  currentWishlistMember: WishlistMember;

  constructor(private wishlistMemberService: WishlistMemberService,
              private authService: AuthService,
              private alertify: AlertifyService,
              private route: ActivatedRoute,
              private modalService: NgbModal) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.wishlistMember = data.wishlistMember;
      console.log(this.wishlistMember.shippingAddress);
    });
    // this.member = this.authService.currentMember;
    this.wishlistMemberService.getWishlistMember(this.authService.currentMember.id).subscribe(wishlistMember => {
      this.currentWishlistMember = wishlistMember;
    }, error => {
      this.alertify.error(error);
    });
  }

  give(gift: Gift) {
    this.wishlistMemberService.giveGift(gift).subscribe((result: WishlistMember) => {
      this.wishlistMember = result;
    }, error => {
      this.alertify.error(error);
    });
  }

  promptTakeback(gift: Gift) {
    if (gift.createdById !== gift.fromId) {
      this.takeback(gift);
      return false;
    }
    this.alertify.confirm('Are you sure you wish to Remove this gift that you added?', () => {
      this.takeback(gift);
    });
    return false;
  }

  takeback(gift: Gift) {
    this.wishlistMemberService.takebackGift(gift).subscribe((result: WishlistMember) => {
      this.wishlistMember = result;
    }, error => {
      this.alertify.error(error);
    });
  }
}
