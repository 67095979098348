import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ClueChase } from '../_models/clueChase';
import { ClueChaseLocation } from '../_models/clueChaseLocation';
import { Location } from '../_models/location';
import { LocationUsage } from '../_models/locationUsage';

@Injectable({
  providedIn: 'root'
})

export class ClueChaseService {

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getPublicGames(latitude: number, longitude: number, radius: number): Observable<ClueChase[]> {
    return this.http.get<ClueChase[]>(this.baseUrl + 'clueChase/publicGames/' + latitude + '/' + longitude + '/' + radius);
  }

  getGame(gameKey: string): Observable<ClueChase> {
    return this.http.get<ClueChase>(this.baseUrl + 'clueChase/' + gameKey);
  }
  
  myGames(): Observable<ClueChase[]> {
    return this.http.get<ClueChase[]>(this.baseUrl + 'clueChase/myGames');
  }

  myLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this.baseUrl + 'clueChase/myLocations');
  }

  locations(clueChaseId: number): Observable<Location[]> {
    var result = this.http.get<Location[]>(this.baseUrl + 'clueChase/locations/' + clueChaseId);
    return result;
  }

  locationUsage(locationId: number, gameId: string, memberId: number): Observable<LocationUsage> {
    let locationUsage = <LocationUsage> { id: 0, locationId: locationId, gameId: gameId, memberId: memberId };
    return this.http.post<LocationUsage>(this.baseUrl + 'clueChase/usage', locationUsage);
  }

  createGame(clueChase: ClueChase): Observable<ClueChase> {
    return this.http.post<ClueChase>(this.baseUrl + 'clueChase/create', clueChase);
  }

  updateGame(clueChase: ClueChase): Observable<ClueChase> {
    return this.http.post<ClueChase>(this.baseUrl + 'clueChase/save', clueChase);
  }

  deleteGame(clueChase: ClueChase): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'clueChase/delete', clueChase);
  }

  addLocation(clueChaseId: number, locationId: number) : Observable<ClueChaseLocation> {
    return this.http.post<ClueChaseLocation>(this.baseUrl + 'clueChase/addClueChaseLocation/' + clueChaseId + '/' + locationId, {});
  }

  deleteClueChaseLocation(clueChaseLocationId: number) : Observable<ClueChaseLocation[]> {
    return this.http.post<ClueChaseLocation[]>(this.baseUrl + 'clueChase/removeClueChaseLocation/' + clueChaseLocationId, {});
  }

  createLocation(location: Location): Observable<Location> {
    return this.http.post<Location> (this.baseUrl + 'clueChase/createLocation', location);
  }

  updateLocation(location: Location): Observable<Location> {
    return this.http.post<Location> (this.baseUrl + 'clueChase/updateLocation', location);
  }

  deleteLocation(location: Location): Observable<number> {
    return this.http.post<number>(this.baseUrl + 'clueChase/deleteLocation', location);
  }
}
